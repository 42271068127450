import { Box, Button, Grid, Typography } from "@mui/material"
import { work, getCategories } from "../../assets/work";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import strings from "../../assets/locals/locals";
import LanguageContext from "../../context/language-context";
import "./our-work.css"
export const OurWork = () => {
  const [data, setData] = useState([]);
  const [cat, setCat] = useState("all");
  const { lang } = useContext(LanguageContext);
  const categories = getCategories(lang)
  strings.setLanguage(lang);
  useEffect(() => {
    if (cat === "all") {
      setData(work)
    }
    else {
      setData(work.filter(el => el.category === cat));
    }
  }, [cat])
  return (
    <Grid container className="mainContainer">
      <Grid item xs={12}>
        <Typography variant="h2" fontSize="3rem" textAlign="center" color="#f6af61">{strings.ourWorkAndProjects}</Typography>
        <Grid container display={"flex"} justifyContent={"center"}>
          <Grid item xs={12} md={10}>
            <Grid container justifyContent={"center"}>
              <Grid item xs={12} justifyContent={"center"} display={"flex"} flexWrap={"wrap"} marginTop={4}>
                {categories.map(({ cat, title }) => {
                  return (
                    <Button className="mainButton" key={cat} onClick={() => setCat(cat)}>{title}</Button>
                  )
                })}
              </Grid>
              {data.map(({ captions, id, image }) => {
                return (
                  <Grid item xs={12} sm={8} md={6} lg={3} key={id} padding={4} >
                    {/* <Link to="/categories"> */}
                    <Grid className="overLayContainer">
                      <Grid container className="overLay" padding={2}>
                        <Grid container height={"100%"} alignItems={"center"} className="border">
                          {captions.map((el, index) => {
                            return (
                              <Grid item xs={12} key={index} className="captions"> {el} </Grid>
                            )
                          })}
                        </Grid>
                      </Grid>
                      <Box
                        component="img"
                        sx={{
                          width: "100%",
                          maxHeight: "250px"
                        }}
                        alt={captions.join(" ")}
                        src={image}
                      />
                    </Grid>
                    {/* </Link> */}

                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>

      </Grid>

    </Grid >
  )
}
