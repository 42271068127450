import { Box, Grid, Typography } from '@mui/material'
import { useContext } from 'react'
import LanguageContext from '../../context/language-context'
import strings from '../../assets/locals/locals';
import "./products.css"
import cornerImage from "../../assets/images/cornerImage.jpg"
import spacerImage from "../../assets/images/spacer.jpg";
export const Products = () => {
    const { lang } = useContext(LanguageContext);
    strings.setLanguage(lang);
    const glasstypesAndSizes = [
        strings.clearFloatThick,
        strings.tintedColored,
        strings.reflectiveGlass,
        strings.extraClear,
        strings.acidEtched,
        strings.mirrorClearBronzeGray,
        strings.cotedGlass,
        strings.hdGreyBronzeBlueGreen,
        strings.hdePlusBronzeBlueGreen,
        strings.hbNeutral70D,
        strings.sliver20,
        strings.sunluxChromaGrey,
    ]
    const spacers = [
        strings.spacer5,
        strings.spacer8,
        strings.spacer9,
        strings.spacer11,
        strings.spacer13,
        strings.spacer14,
        strings.spacer15,

    ]
    const corners = [
        strings.corner5,
        strings.corner8,
        strings.corner9,
        strings.corner11,
        strings.corner13,
        strings.corner14,
        strings.corner15,

    ]

    return (
        <Grid container className="mainContainer"  >
            <Grid
                container
                className="products content animate__animated animate__bounceInUp"
                id="products"
                justifyContent={"center"}
                display={"flex"}
                alignItems={"center"}>
                <Grid item xs={12} marginTop={3} >
                    <Typography variant={"h2"} className="title" textAlign={"center"} padding={3}>{strings.ourProducts}</Typography>
                </Grid>
                <Grid item xs={10} sm={12} md={6} className="border" padding={2} margin={3} minHeight={"70vh"}>
                    <Typography variant="h4" className="subTitle">
                        {strings.ourGlassTypesAndSizes}:
                    </Typography>
                    <ul className="productsList">
                        {glasstypesAndSizes.map((el, index) => {
                            return <li key={index} className={"text"}  > {el}</li>
                        })}
                    </ul>
                    <Typography variant="h4" className="subTitle">
                        {strings.OurGlassSource}
                    </Typography>
                    <Typography variant="p" marginTop={3} display={"block"} className={"text"} margin={2} >
                        {strings.Pilkington} , {strings.Guardian} , {strings.AGC} , {strings.Sphinx} , {strings.Emirates} ,{strings.ObikanGlass} , {strings.ufgGlass} ,{strings.riderGlass}
                    </Typography>
                </Grid>
                <Grid item xs={10} sm={12} md={5} className="border" padding={3} marginTop={3} minHeight={"70vh"} marginBottom={3}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h4" className="subTitle">
                                {strings.spacerAndCorner}:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ul className="productsList">
                                {spacers.map((el, index) => {
                                    return <li key={index} className={"text"} > {el} </li>
                                })}
                            </ul>
                        </Grid>
                        <Grid item xs={12} md={6} display="flex" alignItems={"center"} justifyContent={"center"}>
                            <Box
                                component="img"
                                sx={{
                                    height: 233,
                                    width: 350,
                                    maxHeight: { xs: 233, md: 167 },
                                    maxWidth: { xs: 350, md: 250 },
                                }}
                                alt="Spacer Image"
                                src={spacerImage}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className="subTitle" variant="p" marginY={3}> {strings.corner} </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ul className="productsList">
                                {corners.map((el, index) => {
                                    return <li key={index} className={"text"} > {el} </li>
                                })}
                            </ul>
                        </Grid>
                        <Grid item xs={12} md={6} display="flex" alignItems={"center"} justifyContent={"center"}>
                            <Box
                                component="img"
                                sx={{
                                    height: 233,
                                    width: 350,
                                    maxHeight: { xs: 233, md: 167 },
                                    maxWidth: { xs: 350, md: 250 },
                                }}
                                alt="Corner Image"
                                src={cornerImage}
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Grid >
    )
}
