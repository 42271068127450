import { Box, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom';


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import "./items.css"
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Pagination, Navigation } from 'swiper/modules';
import strings from '../../assets/locals/locals';
import LanguageContext from '../../context/language-context';
import getCategories from '../../assets/ourCategories';


export const Items = (props) => {
    const { state } = useLocation();
    const { lang } = useContext(LanguageContext);
    const cat = getCategories(lang)[state.index];
    strings.setLanguage(lang);
    useEffect(() => {
    }, [lang]);
    return (
        <Grid container className="mainContainer" marginTop={4}>
            <Grid item xs={12} md={8}>
                <Grid container>
                    <Grid item xs={12} textAlign={"center"}>
                        <Typography variant="p" className="heading">{cat.description}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Swiper
                            pagination={{
                                type: 'fraction',
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {cat.images.map((el, index) => {
                                return (
                                    <SwiperSlide>
                                        <Grid container display={"flex"} justifyContent={"center"} alignItems={"center"} height={"100%"}>
                                            <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                <Box
                                                    key={index}
                                                    component="img"
                                                    sx={{
                                                        height: "100%",
                                                        width: "100%",
                                                        maxHeight: "100%",
                                                        maxWidth: "600px"
                                                    }}
                                                    alt="The house from the offer."
                                                    src={el.image}
                                                />
                                            </Grid>
                                            <Grid item xs={12} marginTop={4}>
                                                <Typography variant={"p"} className="menuID"> {strings.itemID} : {el.code}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant={"p"} className="heading"> {el.features}</Typography>

                                            </Grid>
                                        </Grid>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
