// import BACKSPLASH from "./images/categories/BKS.jpg";
import BALUSTRADES from "./images/categories/BAL.jpg";
import SHOWERS from "./images/categories/SHW.jpg";
import PARTITIONS from "./images/categories/DIV.jpg";
// import WALL_CLADDING from "./images/categories/WLC.jpg";
import COUNTER_TOPS from "./images/categories/CNT.jpg";
import TABLE_TOPS from "./images/categories/TBL.jpg";
// import WOOD from "./images/categories/WOD.jpg";
// import STONE from "./images/categories/STN.jpg";
// import TEXTURE from "./images/categories/TEX.jpg";
import PATTERNS from "./images/categories/PAT.jpg";

import BIRD_SAFE_PATTERNS from "./images/categories/BirdsNew-14.jpg";
import PIXEL_BLASTER_4_EFFECTS from "./images/categories/red-15.jpg";
import GOLD_PATTERNS from "./images/categories/gold.jpg";
// import CMIX from "./images/categories/last-17.jpg";
import PvbLaminationFilm from "./images/pvbLaminationFilm.jpg";
import PvbLaminationFilm2 from "./images/PVBLaminationFilm2.jpeg";
import PvbLaminationFilm3 from "./images/pvbLaminationFilm.jpg";
import soundControlInterLayers from "./images/soundControlInterlayers.jpg";
import soundControlInterLayers2 from "./images/soundControlInterlayers3.jpg";
import soundControlInterLayers3 from "./images/soundControlInterlayers4.jpg";
import soundControlInterLayers4 from "./images/soundControlInterLayers2.jpg";

import structuralAndSecurityInterlayers from "./images/structuralAndSecurityInterlayers.jpg";
import structuralAndSecurityInterlayers2 from "./images/structuralAndSecurityInterlayers2.jpg";
import structuralAndSecurityInterlayers3 from "./images/structuralAndSecurityInterlayers3.jpg";
import structuralAndSecurityInterlayers4 from "./images/structuralAndSecurityInterlayers4.jpg";
import Door from "./images/categories/DOR.jpg";
import strings from "./locals/locals";

// start the backsplash images ;
const getCategories = (lang) => {
  strings.setLanguage(lang);
  return [
    // {
    //   image: BACKSPLASH,
    //   href: "BACKSPLASH",
    //   title: strings.BACKSPLASH,
    //   description: strings.BACKSPLASH_DESCRIPTION,
    //   images: [
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/199341219230062020141136.jpg", // 1
    //       code: "0WSHW7LU",
    //       features: [
    //         "Lines | Squares | Green | Opaque | Seamless | Nature | Leaves | Flowers | Vector | Floral | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/26403974330062020143938.jpg", // 2
    //       code: "167156C5",
    //       features: [
    //         "Lines | Circles | Colors | Blue | Brown | Patterns | Opaque | Seamless | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/204771201030072020105033.jpg", // 3
    //       code: "1DEP1P6U",
    //       features: [
    //         "Lines | Brown | Surfaces | Opaque | Nature | Dark | Trees | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/194628836730072020105158.jpg", // 4
    //       code: "1G13H7CK",
    //       features: [
    //         "Lines | Black | Grey | Surfaces | Opaque | Nature | Dark | Trees | Texture  ",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/196297878920092022142339.jpg",
    //       code: "1ZKIBS5U",
    //       features: [
    //         "Blue | Green | Grey | Cement | Opaque | Nature | Earth | Mountains | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/61664033628072020102307.jpg", // 6
    //       code: "28TZRGLG",
    //       features: [
    //         "Lines | Squares | Geometric | Triangles | Blue | Black | Brown | Patterns | Opaque | Seamless | Waves | Vector",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/191888415028072020100750.jpg", // 7
    //       code: "3031CGJU",
    //       features: [
    //         "Lines | Blue | Red | Purple | Pink | Patterns | Opaque | Nature | Flowers | Vector | Floral | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/213498171301072020132851.jpg", // 8
    //       code: "37C858QD",
    //       features: ["Red | Brown | Grey | Marble | White"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/5219115129072020131200.jpg", // 9
    //       code: "3P1IJKO3",
    //       features: [
    //         "Lines | Colors | Red | Black | Purple | Pink | Patterns | Translucent | Graidients | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/129103579928072020101739.jpg", // 10
    //       code: "5WSUDGJV",
    //       features: [
    //         "Lines | Colors | Green | Red | Pink | Patterns | Opaque | Seamless | Leaves | Flowers | Vector | Floral | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/213498171301072020132851.jpg", // 10
    //       code: "37C858QD",
    //       features: ["Red | Brown | Grey | Marble | White"],
    //     },

    //     // new
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/5219115129072020131200.jpg", // 10
    //       code: "3P1IJKO3",
    //       features: [
    //         "Lines | Colors | Red | Black | Purple | Pink | Patterns | Translucent | Graidients | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/129103579928072020101739.jpg", // 10
    //       code: "5WSUDGJV",
    //       features: [
    //         "Lines | Colors | Green | Red | Pink | Patterns | Opaque | Seamless | Leaves | Flowers | Vector | Floral | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/140211306630062020142649.jpg", // 10
    //       code: "6GXPH56T",
    //       features: [
    //         "Lines | Circles | Colors | Green | Pink | Patterns | Opaque | Seamless | Leaves | Flowers | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/113709176512072020123058.jpg", // 10
    //       code: "37C858QD",
    //       features: ["Red | Brown | Grey | Marble | White"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/5219115129072020131200.jpg", // 10
    //       code: "3P1IJKO3",
    //       features: [
    //         "Lines | Colors | Red | Black | Purple | Pink | Patterns | Translucent | Graidients | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/129103579928072020101739.jpg", // 10
    //       code: "5WSUDGJV",
    //       features: [
    //         "Lines | Colors | Green | Red | Pink | Patterns | Opaque | Seamless | Leaves | Flowers | Vector | Floral | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/140211306630062020142649.jpg", // 10
    //       code: "6GXPH56T",
    //       features: [
    //         "Lines | Circles | Colors | Green | Pink | Patterns | Opaque | Seamless | Leaves | Flowers | Vector | White",
    //       ],
    //     },
    //     // new
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/113709176512072020123058.jpg", // 10
    //       code: "7C7LT8NF",
    //       features: [
    //         "Lines | Circles | Colors | Green | Pink | Patterns | Opaque | Seamless | Leaves | Flowers | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/140211306630062020142649.jpg", // 10
    //       code: "6GXPH56T",
    //       features: [
    //         "Lines | Ornamants | Blue | Grey | Cement | Patterns | Surfaces | Opaque | Flowers | White | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/157811253530062020142450.jpg", // 10
    //       code: "7C8VQ2KA",
    //       features: [
    //         "Lines | Squares | Circles | Colors | Green | Pink | Patterns | Opaque | Seamless | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/156444403202082020113823.jpg", // 10
    //       code: "82U45KPP",
    //       features: ["Cracks | Marble | Surfaces | White | Stone | Texture"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/71105809924062020073742.jpg", // 10
    //       code: "839MS9T8",
    //       features: [
    //         "Lines | Colors | Blue | Black | Brown | Purple | Graidients | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/189553136728072020101135.jpg", // 10
    //       code: "8DQ5AYG6",
    //       features: [
    //         "Lines | Dots | Circles | Colors | Blue | Brown | Patterns | Opaque | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/70634647130062020142208.jpg", // 10
    //       code: "96W19RKK",
    //       features: [
    //         "Dots | Geometric | Colors | Green | Red | Pink | Patterns | Opaque | Seamless | Leaves | Flowers | Vector | Floral",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/179982954001072020132525.jpg", // 10
    //       code: "98US47X5",
    //       features: ["Red | Brown | Orange | Marble | White"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/183309606220092022141604.jpg", // 10
    //       code: "9C4EELR9",
    //       features: [
    //         "Green | Red | Pink | Colorful | Cement | Graidients | Opaque | Nature | Flowers | Plants | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/43991127530062020142326.jpg", // 10
    //       code: "9GN19VP6",
    //       features: [
    //         "Lines | Patterns | Opaque | Seamless | Nature | Leaves | Flowers | Vines | Plants | Floral",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/33218100028072020102124.jpg", // 10
    //       code: "AAP9CJQX",
    //       features: [
    //         "Lines | Squares | Circles | Geometric | Triangles | Brown | Pink | Patterns | Opaque | Seamless | Waves | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/103817367130062020130556.jpg", // 10
    //       code: "ALF6M5DY",
    //       features: [
    //         "Lines | Squares | Geometric | Rectangles | Colors | Green | Pink | Colorful | Patterns | Opaque | Seamless | Vector",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/166723736428072020103428.jpg", // 10
    //       code: "CLI3E77S",
    //       features: [
    //         "Lines | Squares | Circles | Diamonds | Geometric | Triangles | Green | Black | Brown | Patterns | Opaque | Seamless | Vector",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/4171087330072020101233.jpg", // 10
    //       code: "CT9PZZGL",
    //       features: ["Turquoise | Marble | Surfaces | Opaque | Stone"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/142777735130072020101502.jpg", // 10
    //       code: "CTSSRMP9",
    //       features: [
    //         "Turquoise | Marble | Surfaces | Opaque | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/70619555824062020075248.jpg", // 10
    //       code: "DEO5X7G7",
    //       features: [
    //         "Brown | Orange | Grey | Marble | Sand | Surfaces | Opaque | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/50142531620092022143227.jpg", // 10
    //       code: "DI8MXHP8",
    //       features: [
    //         "Yellow | Orange | Grey | Cement | Opaque | Nature | Plants | Earth | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/140211306630062020142649.jpg", // 10
    //       code: "6GXPH56T",
    //       features: [
    //         "Lines | Circles | Colors | Green | Pink | Patterns | Opaque | Seamless | Leaves | Flowers | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/21155106230072020104907.jpg", // 10
    //       code: "DYBMGSF4",
    //       features: [
    //         "Lines | Black | Grey | Surfaces | Opaque | Nature | Trees | White | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/1603057429072020130806.jpg", // 10
    //       code: "DYDKD7AK",
    //       features: [
    //         "Lines | Colors | Blue | Yellow | Brown | Purple | Pink | Orange | Graidients | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/1603057429072020130806.jpg", // 10
    //       code: "DYDKD7AK",
    //       features: [
    //         "Lines | Colors | Blue | Yellow | Brown | Purple | Pink | Orange | Graidients | Vector | White",
    //       ],
    //     },
    //     // new
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/87528633728072020103130.jpg", // 10
    //       code: "IR6BBQ2P",
    //       features: [
    //         "Lines | Squares | Circles | Geometric | Triangles | Green | Black | Brown | Marble | Cement | Patterns | Opaque | Seamless | Waves | Vector | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/136400305201072020122333.jpg", // 10
    //       code: "JCYIKQ18",
    //       features: [
    //         "Lines | Green | Yellow | Brown | Patterns | Graidients | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/17788298612072020123210.jpg", // 10
    //       code: "KIW7HHXX",
    //       features: [
    //         "Lines | Purple | Pink | Grey | Colorful | Cement | Patterns | Surfaces | Opaque | Tiles | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/166978357324062020074920.jpg", // 10
    //       code: "KPP0WX57",
    //       features: [
    //         "Squares | Cracks | Colors | Green | Red | Black | Brown | Purple | Marble | Surfaces | Opaque | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/142168135822062020144156.jpg", // 10
    //       code: "KW94Y94V",
    //       features: [
    //         "Lines | Squares | Geometric | Rectangles | Colors | Green | Pink | Orange | Colorful | Seamless | Vector",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/89625950730062020143817.jpg", // 10
    //       code: "LO5SMUL9",
    //       features: [
    //         "Lines | Squares | Geometric | Brown | Pink | Patterns | Opaque | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/58903855128072020102737.jpg", // 10
    //       code: "M1Q6V7SY",
    //       features: [
    //         "Lines | Circles | Diamonds | Geometric | Blue | Green | Black | Patterns | Opaque | Seamless | Vector",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/80430525722062020145143.jpg", // 10
    //       code: "ME1FLLYJ",
    //       features: [
    //         "Geometric | Blue | Green | Pink | Arabian | Colorful | Patterns | Graidients | Opaque | Seamless | Vector",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/102537415724062020073914.jpg", // 10
    //       code: "N5RJAHOF",
    //       features: [
    //         "Lines | Squares | Rectangles | Colors | Blue | Green | Yellow | Black | Orange | Colorful | Patterns | Graidients | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/193036356401072020135050.jpg", // 10
    //       code: "NZX126OY",
    //       features: ["Brown | Grey | Opaque"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/160981869002082020130037.jpg", // 10
    //       code: "O2UXHDMZ",
    //       features: [
    //         "Dots | Red | Brown | Marble | Surfaces | Opaque | Nature | Dark | Trees | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/13300404222062020150728.jpg", // 10
    //       code: "O8THLIH4",
    //       features: [
    //         "Lines | Geometric | Green | Brown | Patterns | Opaque | Seamless | Vector",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/159803672924062020075937.jpg", // 10
    //       code: "IHN85A05",
    //       features: [
    //         "Black | Brown | Grey | Marble | Surfaces | Opaque | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/65718482920092022144012.jpg", // 10
    //       code: "OO7D3A52",
    //       features: [
    //         "Yellow | Orange | Grey | Cement | Fade | Opaque | Nature | Plants | Earth | Stone",
    //       ],
    //     },

    //     // new
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/184169782730062020141322.jpg", // 10
    //       code: "OVLJ0C8",
    //       features: [
    //         "Lines | Dots | Colors | Green | Red | Colorful | Patterns | Graidients | Opaque | Seamless | Nature | Leaves | Flowers | Vector | Floral | White",
    //       ],
    //     },

    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/61920791628072020102931.jpg", // 10
    //       code: "OXSO3URC",
    //       features: [
    //         "Lines | Circles | Geometric | Black | Grey | Marble | Cement | Nature | Leaves | Vines | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/62789812501072020124434.jpg", // 10
    //       code: "OYGQX5KT",
    //       features: [
    //         "Lines | Circles | Geometric | Triangles | PixelBlaster 4 | Colors | Blue | Seamless | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/74287221630072020130633.jpg", // 10
    //       code: "Q5TDPZQB",
    //       features: [
    //         "Lines | Brown | Surfaces | Opaque | Nature | Bright | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/193983125830072020105312.jpg", // 10
    //       code: "QAEMV9U7",
    //       features: [
    //         "Lines | Brown | Surfaces | Opaque | Nature | Trees | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/155698594022062020145555.jpg", // 10
    //       code: "QT04W8GE",
    //       features: [
    //         "Lines | Colors | Green | Pink | Opaque | Leaves | Flowers | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/39332647422062020144819.jpg", // 10
    //       code: "R601IUPJ",
    //       features: [
    //         "Lines | Squares | Circles | Geometric | Blue | Colorful | Patterns | Opaque | Seamless | Infinity | Vector",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/199413684906072020114033.jpg", // 10
    //       code: "RPMUYCA9",
    //       features: [
    //         "Lines | Squares | Black | Grey | Cement | Patterns | Surfaces | Opaque | Flowers | White | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/156633813029072020143504.jpg", // 10
    //       code: "RRK65IOF",
    //       features: ["Squares | Grey | Surfaces | Opaque | White | Tiles"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/181979539022062020144340.jpg", // 10
    //       code: "RTI5G5TZ",
    //       features: [
    //         "Lines | Circles | Green | Pink | Colorful | Patterns | Graidients | Opaque | Seamless | Infinity | Vector",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/119745361906072020112512.jpg", // 10
    //       code: "S1E4OA6F",
    //       features: [
    //         "Lines | Circles | Orange | Grey | Colorful | Cement | Patterns | Surfaces | Opaque | Tiles | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/137048244030062020142817.jpg", // 10
    //       code: "SG1EED1J",
    //       features: [
    //         "Lines | Dots | Circles | Geometric | Colors | Blue | Red | Brown | Grey | Patterns | Opaque | Seamless | Vector",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/140995157430062020134254.jpg", // 10
    //       code: "STPQWW9",
    //       features: [
    //         "Geometric | Colors | Blue | Green | Pink | Colorful | Patterns | Graidients | Opaque | Seamless | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/88828356801072020123733.jpg", // 10
    //       code: "USNP4PFD",
    //       features: [
    //         "Lines | Squares | Geometric | Rectangles | Triangles | PixelBlaster 4 | Blue | Brown | Purple | Orange | Seamless",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/96146736622062020150243.jpg", // 10
    //       code: "VE2ABT9K",
    //       features: [
    //         "Lines | Red | Colorful | Patterns | Graidients | Opaque | Leaves | Flowers | Vector | Floral | White",
    //       ],
    //     },
    //     // new
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/165524054806072020112705.jpg", // 10
    //       code: "VJ2H8XM9",
    //       features: [
    //         "Lines | Squares | Circles | Blue | Grey | Cement | Patterns | Surfaces | Opaque | White | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/19688726701072020130221.jpg", // 10
    //       code: "WT4XXID9",
    //       features: [
    //         "Lines | PixelBlaster 4 | Colors | Red | Patterns | Graidients | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/72875429622062020145325.jpg", // 10
    //       code: "WUBYAW95",
    //       features: [
    //         "Lines | Green | Patterns | Opaque | Seamless | Nature | Leaves | Flowers | Vines | Vector | Floral | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/122822375101072020132620.jpg", // 10
    //       code: "XIW3TWG5",
    //       features: ["Orange | Marble | Sand | Opaque | White"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/151273600824062020075123.jpg", // 10
    //       code: "Z4Y8NSB8",
    //       features: [
    //         "Squares | Brown | Pink | Grey | Marble | Surfaces | Opaque | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/11214461201072020123958.jpg", // 10
    //       code: "ZM89IJVR",
    //       features: [
    //         "Dots | Squares | Circles | Geometric | Triangles | Blue | Black | Seamless",
    //       ],
    //     },
    //   ],
    // },
    {
      image: BALUSTRADES,
      href: "BALUSTRADES",
      title: strings.BALUSTRADES,
      description: strings.BALUSTRADES_DESCRIPTION,
      images: [
        {
          image:
            "https://glassdesign.online/images/glassdesign/thumbs/listing/3518281622062020143806.jpg",
          code: "RZD9PFCU",
          features: [
            "Green | Brown | Patterns | See-Through | Seamless | Nature | Leaves | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/30354236601072020104922.jpg",
          code: "CCKMDDOP",
          features: [
            "Lines | Squares | Green | Opaque | Seamless | Nature | Leaves | Flowers | Vector | Floral | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/20611287222062020142050.jpg",
          code: "UZCE1OOQ",
          features: [
            "Lines | Dots | Diamonds | Geometric | Triangles | Green | Patterns | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/75534682429072020114359.jpg",
          code: "4HSP7LXV",
          features: [
            "Lines | Dots | Diamonds | Geometric | Black | Brown | Patterns | See-Through | Vector | Zig-Zag",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/166153072830072020111842.jpg",
          code: "NHDIY766",
          features: [
            "Lines | Brown | Opaque | Nature | Dark | Trees | Texture",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/182659224401072020105045.jpg",
          code: "FAW8C0NC",
          features: [
            "Lines | Geometric | Green | Brown | Patterns | See-Through | Seamless | Leaves | Flowers | Vector | Rope | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/42578079229072020111345.jpg",
          code: "E1FJUFSL",
          features: [
            "Lines | Squares | Geometric | Triangles | Black | Patterns | See-Through | Seamless | Waves | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/30978483801072020113434.jpg",
          code: "GN9SHOWI",
          features: [
            "Lines | Diamonds | Geometric | Colors | Blue | Brown | Grey | Patterns | See-Through | Seamless | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/125897106029072020111025.jpg",
          code: "C426RRIE",
          features: [
            "Circles | Blue | Green | Grey | Patterns | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/72834679122062020143434.jpg",
          code: "38HIRFPK",
          features: [
            "Lines | Dots | Blue | Yellow | Orange | Patterns | See-Through | Seamless | Leaves | Flowers | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/150484182129072020111623.jpg",
          code: "04M6CH39",
          features: [
            "Lines | Dots | Geometric | Black | Patterns | Nature | Leaves | Flowers | Vines | Vector | Zig-Zag",
          ],
        },

        // new new
        {
          image:
            "https://glassdesign.online/images/glassdesign/125897106029072020111025.jpg",
          code: "C426RRIE",
          features: [
            "Circles | Blue | Green | Grey | Patterns | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/208784077922062020143604.jpg",
          code: "PUDJ0QDE",
          features: [
            "Dots | Green | Brown | Patterns | See-Through | Seamless | Nature | Leaves | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/208784077922062020143604.jpg",
          code: "NJQI248M",
          features: [
            "Lines | Circles | Blue | Brown | Patterns | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/16962668722062020142506.jpg",
          code: "0L472TNR",
          features: [
            "Lines | Squares | Brown | Patterns | See-Through | Seamless | Nature | Leaves | Flowers | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/166153072830072020111842.jpg",
          code: "NHDIY766",
          features: [
            "Lines | Brown | Opaque | Nature | Dark | Trees | Texture",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/92262737729072020110656.jpg",
          code: "5MKPHNG7",
          features: [
            "Lines | Dots | Circles | Geometric | Green | Pink | Patterns | See-Through | Seamless | Flowers | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/75534682429072020114359.jpg",
          code: "4HSP7LXV",
          features: [
            "Lines | Dots | Diamonds | Geometric | Black | Brown | Patterns | See-Through | Vector | Zig-Zag",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/81234773122062020143028.jpg",
          code: "J9LV7R0U",
          features: [
            "Dots | Circles | Geometric | Green | Patterns | See-Through | Seamless | Nature | Leaves | Flowers | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/29247231222062020143923.jpg",
          code: "2FMZI025",
          features: [
            "Lines | Squares | Geometric | Blue | Brown | Patterns | See-Through | Seamless | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/182659224401072020105045.jpg",
          code: "FAW8C0NC",
          features: [
            "Lines | Geometric | Green | Brown | Patterns | See-Through | Seamless | Leaves | Flowers | Vector | Rope | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/92027895401072020105407.jpg",
          code: "AFKPPIJZ",
          features: [
            "Lines | Geometric | Green | Brown | Pink | Patterns | See-Through | Seamless | Nature | Leaves | Flowers | Vector | Floral | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/20160332301072020112256.jpg",
          code: "IC9J7X1Q",
          features: [
            "Lines | Diamonds | Geometric | Blue | Red | Brown | Patterns | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/42578079229072020111345.jpg",
          code: "E1FJUFSL",
          features: [
            "LLines | Squares | Geometric | Triangles | Black | Patterns | See-Through | Seamless | Waves | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/167907734830072020112024.jpg",
          code: "Z2KQ6EC5",
          features: ["Lines | Brown | Surfaces | Opaque | Nature | Texture"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/20611287222062020142050.jpg",
          code: "UZCE1OOQ",
          features: [
            "Lines | Dots | Diamonds | Geometric | Triangles | Green | Patterns | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/139052940601072020113704.jpg",
          code: "3QNFI8G1",
          features: [
            "Lines | Circles | Blue | Black | Brown | Patterns | See-Through | Seamless | Vector",
          ],
        },
        // new
        {
          image:
            "https://glassdesign.online/images/glassdesign/3518281622062020143806.jpg",
          code: "LQX1COU7",
          features: [
            "Lines | Circles | Blue | Grey | Patterns | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/85813597029072020111741.jpg",
          code: "1PJJ53XM",
          features: [
            "Lines | Dots | Diamonds | Geometric | Black | Brown | Patterns | See-Through | Nature | Leaves | Vines | Vector | Zig-Zag",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/18865202101072020105535.jpg",
          code: "5E7ZBFH0",
          features: [
            "Dots | Colors | Blue | Green | Patterns | See-Through | Seamless | Leaves | Flowers | Vector | Floral | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/30354236601072020104922.jpg",
          code: "CCKMDDOP",
          features: [
            "Lines | Diamonds | Geometric | Green | Patterns | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/10204324029072020110346.jpg",
          code: "TMXTG5KY",
          features: [
            "Lines | Geometric | Colors | Green | Red | Pink | Patterns | Graidients | Fade | See-Through | Seamless | Leaves | Flowers | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/1468261427102022132841.jpg",
          code: "MS0JBL95",
          features: [
            "Lines | Geometric | Criss-Cross | Black | Patterns | See-Through | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/148297305029072020114147.jpg",
          code: "G2INKFCW",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Triangles | Brown | Patterns | See-Through | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/185206969322062020142328.jpg",
          code: "475ZJSTL",
          features: [
            "Lines | Geometric | Green | Brown | Patterns | See-Through | Seamless | Flowers | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/6377964101072020105158.jpg",
          code: "5I66N7EE",
          features: [
            "Lines | Green | Brown | Pink | Patterns | See-Through | Seamless | Leaves | Flowers | Vector | Floral",
          ],
        },
      ],
    },
    {
      image: SHOWERS,
      href: "SHOWERS",
      title: strings.SHOWERS,
      description: strings.showersDescription,
      images: [
        {
          image:
            "https://glassdesign.online/images/glassdesign/14117175924062020073559.jpg", // 1
          code: "246JU7M2",
          features: [
            "Lines | Squares | Colors | Red | Pink | Graidients | Fade",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/192836891322062020153852.jpg", // 2
          code: "N52M83RU",
          features: [
            "Lines | Circles | Colors | Blue | Black | Brown | Graidients | See-Through | Seamless | Privacy",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/192836891322062020153852.jpg", // 3
          code: "BC59Y9PD",
          features: [
            "Lines | Dots | Circles | Geometric | Green | Red | Purple | Light Blue | Patterns | Graidients | Fade | See-Through | Seamless | Privacy | Flowers | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/111995963301072020102022.jpg", // 4
          code: "IH2P1KTH",
          features: [
            "Lines | Circles | Geometric | Yellow | Brown | Patterns | Fade | See-Through | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/37560303524062020072540.jpg",
          code: "AYLPB20E",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/59666379429072020101910.jpg", // 6
          code: "S1JD3JVQ",
          features: [
            "Lines | Dots | Squares | Geometric | Triangles | Blue | Black | Grey | Patterns | Seamless | Waves | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/34589401429072020102039.jpg", // 7
          code: "B4GZPWS1",
          features: [
            "Lines | Dots | Geometric | Triangles | Blue | Green | Brown | Patterns | Seamless | Privacy | Nature | Leaves | Vines | Vecto",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/161998150929072020132745.jpg", // 8
          code: "PTTO2WSA",
          features: [
            "Colors | Blue | Green | Red | Yellow | Brown | Orange | Graidients | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/100411185329072020134909.jpg", // 9
          code: "BBHNIRSS",
          features: [
            "Green | Black | Grey | Patterns | Translucent | Graidients | Transparent | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/200174659312072020123235.jpg", // 10
          code: "FTKLHCZB",
          features: [
            "Lines | Squares | Black | Grey | Cement | Patterns | Surfaces | Opaque | Nature | White | Stone",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/208391324612072020123144.jpg", // 10
          code: "WCZG4N7R",
          features: [
            "Lines | Grey | Cement | Patterns | Surfaces | Opaque | Tiles | Stone",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/65825137301072020125506.jpg", // 10
          code: "J4300Y4L",
          features: [
            "Lines | Geometric | Blue | Light Blue | Seamless | Privacy | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/102903283522032022115238.jpg", // 10
          code: "DQLOYSQ5",
          features: [
            "Lines | Dots | Squares | Circles | Diamonds | Geometric | Patterns | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/141229383724062020073239.jpg", // 10
          code: "EXOWLMDB",
          features: [
            "Lines | Geometric | Black | Purple | Privacy | Vector | White",
          ],
        },
        // new
        {
          image:
            "https://glassdesign.online/images/glassdesign/70329814329072020141054.jpg", // 10
          code: "FS2FAW11",
          features: ["Red | Onyx | Surfaces | Opaque | White"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/82973738824062020073446.jpg", // 10
          code: "WYN7MIF",
          features: [
            "Lines | Blue | Black | Dark Blue | Fade | Privacy | Navy | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/186750509401072020102200.jpg", // 10
          code: "EPS3S7Z9",
          features: [
            "Lines | Geometric | Brown | Patterns | Modern | See-Through | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/153210912629072020135534.jpg", // 10
          code: "O8RIV05K",
          features: [
            "Lines | Dots | Colors | Green | Red | Purple | Pink | Translucent | Graidients | Transparent | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/72547571801072020102645.jpg", // 10
          code: "L4VG4GIZ",
          features: [
            "Lines | Geometric | Triangles | Blue | Green | Patterns | See-Through | Seamless | Privacy | Waves | Water | Spiral | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/34589401429072020102039.jpg", // 10
          code: "B4GZPWS1",
          features: [
            "Lines | Dots | Geometric | Triangles | Blue | Green | Brown | Patterns | Seamless | Privacy | Nature | Leaves | Vines | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/51618167201072020101706.jpg", // 10
          code: "NCLK5MIL",
          features: [
            "Squares | Geometric | Rectangles | Brown | See-Through | Seamless | Privacy",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/10982716030072020064630.jpg", // 10
          code: "Y4F7DEW3",
          features: [
            "Lines | Dots | Squares | Circles | Diamonds | Geometric | Patterns | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/82856886522062020154546.jpg", // 10
          code: "QYZYN3C6",
          features: [
            "Lines | Dots | Circles | Black | Pink | Patterns | See-Through | Seamless | Privacy | Flowers | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/208114060122062020154416.jpg", // 10
          code: "9OMYOSN0",
          features: [
            "Dots | Circles | Green | Red | Black | Pink | Patterns | Graidients | See-Through | Seamless | Privacy | Flowers | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/189752349922062020152904.jpg", // 10
          code: "0VYA5PQX",
          features: [
            "Lines | Rectangles | Colors | Blue | Green | Red | Yellow | Brown | Purple | Pink | Orange | Patterns | Graidients | See-Through | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/133534564422062020153557.jpg", // 10
          code: "CO2Q041O",
          features: [
            "Lines | Geometric | Blue | Green | Red | Purple | Patterns | Graidients | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/26851448030072020064820.jpg", // 10
          code: "LP8LX096",
          features: [
            "Lines | Dots | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/44628443301072020101358.jpg", // 10
          code: "BBFOZ6O1",
          features: [
            "Squares | Colors | Green | Patterns | Translucent | Graidients | Transparent | See-Through | Seamless | Privacy | Nature | Leaves | Flowers | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/24833596401072020122737.jpg", // 10
          code: "ZJGTVCI3",
          features: [
            "Lines | PixelBlaster 4 | Colors | Green | Red | Black | Brown | Graidients | Privacy | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/47820808729072020142113.jpg", // 10
          code: "VIR45466",
          features: [
            "Lines | Cracks | Black | Brown | Grey | Marble | Surfaces | Opaque | White | Stone",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/59666379429072020101910.jpg", // 10
          code: "S1JD3JVQ",
          features: [
            "Lines | Dots | Squares | Geometric | Triangles | Blue | Black | Grey | Patterns | Seamless | Waves | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/59345490929072020100248.jpg", // 10
          code: "SQK4S780",
          features: [
            "Lines | Squares | Geometric | Rectangles | Green | Brown | Patterns | See-Through | Privacy | Vector | Labyrinth | Maze",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/43695126701072020101038.jpg", // 10
          code: "JMUPCXUJ",
          features: [
            "Lines | Squares | Geometric | Rectangles | Blue | Patterns | Graidients | See-Through | Seamless | Privacy | Flowers | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/82856886522062020154546.jpg", // 10
          code: "QYZYN3C6",
          features: [
            "Lines | Dots | Circles | Black | Pink | Patterns | See-Through | Seamless | Privacy | Flowers | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/84924679230072020102051.jpg", // 10
          code: "6T5WCF8U",
          features: [
            "Grey | Marble | Surfaces | Opaque | Dark | Stone | Texture",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/133534564422062020153557.jpg", // 10
          code: "CO2Q041O",
          features: [
            "Lines | Geometric | Blue | Green | Red | Purple | Patterns | Graidients | See-Through | Seamless | Vector",
          ],
        },
        //  new
        {
          image:
            "https://glassdesign.online/images/glassdesign/198771251912072020123012.jpg", // 10
          code: "5IYSEM94",
          features: ["Lines | Diamonds | Black | Grey | Surfaces | Opaque"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/92237316429072020095629.jpg", // 10
          code: "WYLMPST0",
          features: [
            "Lines | Circles | Geometric | Colors | Blue | Black | Brown | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/26851448030072020064820.jpg", // 10
          code: "LP8LX096",
          features: [
            "Lines | Dots | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/100411185329072020134909.jpg", // 10
          code: "BBHNIRSS",
          features: [
            "Green | Black | Grey | Patterns | Translucent | Graidients | Transparent | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/51618167201072020101706.jpg", // 10
          code: "NCLK5MIL",
          features: [
            "Squares | Geometric | Rectangles | Brown | See-Through | Seamless | Privacy",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/14117175924062020073559.jpg", // 10
          code: "246JU7M2",
          features: [
            "Lines | Squares | Colors | Red | Pink | Graidients | Fade",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/153210912629072020135534.jpg", // 10
          code: "O8RIV05K",
          features: [
            "Lines | Dots | Colors | Green | Red | Purple | Pink | Translucent | Graidients | Transparent | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/105096487122062020154701.jpg", // 10
          code: "59PCPOX3",
          features: [
            "Green | Brown | Patterns | See-Through | Seamless | Privacy | Leaves | Plants | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/208114060122062020154416.jpg", // 10
          code: "9OMYOSN0",
          features: [
            "Dots | Circles | Green | Red | Black | Pink | Patterns | Graidients | See-Through | Seamless | Privacy | Flowers | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/192836891322062020153852.jpg", // 10
          code: "N52M83RU",
          features: [
            "Lines | Circles | Colors | Blue | Black | Brown | Graidients | See-Through | Seamless | Privacy",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/157787444201072020101207.jpg", // 10
          code: "5L9PBQBL",
          features: [
            "Lines | Dots | Squares | Circles | Blue | Black | Brown | Patterns | Graidients | See-Through | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/23486599230072020102356.jpg", // 10
          code: "SRSI22E4",
          features: [
            "Black | Dark Blue | Marble | Surfaces | Opaque | Stone | Texture",
          ],
        },
        // new
        {
          image:
            "https://glassdesign.online/images/glassdesign/70329814329072020141054.jpg", // 10
          code: "FS2FAW11",
          features: ["Red | Onyx | Surfaces | Opaque | White"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/113710294124062020074030.jpg", // 10
          code: "SIWW4T6X",
          features: [
            "Lines | Squares | Green | Brown | Graidients | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/160653811329072020102352.jpg", // 10
          code: "JHY08GDN",
          features: ["Lines | Blue | Nature | Leaves | White"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/10982716030072020064630.jpg", // 10
          code: "Y4F7DEW3",
          features: [
            "Lines | Dots | Squares | Circles | Diamonds | Geometric | Patterns | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/34589401429072020102039.jpg", // 10
          code: "B4GZPWS1",
          features: [
            "Lines | Dots | Geometric | Triangles | Blue | Green | Brown | Patterns | Seamless | Privacy | Nature | Leaves | Vines | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/38620687129072020100905.jpg", // 10
          code: "21D55SW8",
          features: [
            "Squares | Diamonds | Geometric | Yellow | Brown | Patterns | See-Through | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/72547571801072020102645.jpg", // 10
          code: "L4VG4GIZ",
          features: [
            "Lines | Geometric | Triangles | Blue | Green | Patterns | See-Through | Seamless | Privacy | Waves | Water | Spiral | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/102903283522032022115238.jpg", // 10
          code: "DQLOYSQ5",
          features: [
            "Lines | Dots | Squares | Circles | Diamonds | Geometric | Patterns | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/18350946101072020100901.jpg", // 10
          code: "QXCF7XQ6",
          features: [
            "Lines | Circles | Geometric | Colors | Blue | Red | Pink | Patterns | Opaque | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/1196398322062020153416.jpg", // 10
          code: "FBU3ACW7",
          features: [
            "Geometric | Arabian | Patterns | See-Through | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/200174659312072020123235.jpg", // 10
          code: "FTKLHCZB",
          features: [
            "Lines | Squares | Black | Grey | Cement | Patterns | Surfaces | Opaque | Nature | White | Stone",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/112341906072020110039.jpg", // 10
          code: "I0LZHXBC",
          features: [
            "Lines | Squares | Grey | Cement | Patterns | Surfaces | Opaque | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/156799951029072020095910.jpg", // 10
          code: "JNWZQAJS",
          features: [
            "Lines | Squares | Geometric | Blue | Patterns | See-Through | Seamless | Privacy | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/189752349922062020152904.jpg", // 10
          code: "0VYA5PQX",
          features: [
            "Lines | Rectangles | Colors | Blue | Green | Red | Yellow | Brown | Purple | Pink | Orange | Patterns | Graidients | See-Through | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/165871490629072020134150.jpg", // 10
          code: "1KLM0F71",
          features: [
            "Lines | Colors | Blue | Yellow | Brown | Orange | Translucent | Graidients | Transparent | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/81935646422062020154011.jpg", // 10
          code: "N52M83RU",
          features: [
            "Squares | Geometric | Rectangles | Blue | Green | Red | Pink | Patterns | Graidients | See-Through | Seamless | Privacy | Flowers | Vector | Floral",
          ],
        },
      ],
    },
    {
      image: PARTITIONS,
      href: "PARTITIONS",
      title: strings.PARTITIONS,
      description: strings.partitionDescription,
      images: [
        {
          image:
            "https://glassdesign.online/images/glassdesign/118981627230072020063805.jpg", // 1
          code: "DKZCIMA1",
          features: [
            "Lines | Squares | Patterns | See-Through | Nature | Leaves | Flowers | Waves | Sea | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/122237736928072020105034.jpg", // 2
          code: "DGF2Z7WV",
          features: [
            "Blue | Pink | Grey | Patterns | Vintage | Seamless | Privacy | Flowers | Vector | Floral | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/10268901529072020095055.jpg", // 3
          code: "KN535VKO",
          features: [
            "Lines | Circles | Geometric | Triangles | Black | Brown | Patterns | Graidients | See-Through | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/18942431922062020151407.jpg", // 4
          code: "LEE9S7TL",
          features: [
            "Lines | Squares | Geometric | Rectangles | Colors | Blue | Green | Red | Brown | Pink | Patterns | Graidients | See-Through | Seamless | Privacy | Flowers | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/159451441128072020111258.jpg",
          code: "TPXWNXGX",
          features: [
            "Lines | Squares | Geometric | Rectangles | Blue | Green | Black | Brown | Graidients | See-Through | Seamless | Privacy | Leaves | Flowers | Vector | Floral | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/195469190101072020123150.jpg", // 6
          code: "3QUH4VQU",
          features: [
            "Lines | PixelBlaster 4 | Black | Grey | Patterns | Graidients | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/911220819042021094433.jpg", // 7
          code: "F2D9KWTV",
          features: ["Lines | Patterns | Seamless | Privacy | Vector"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/192278139819042021083806.jpg", // 8
          code: "CFFURDFQ",
          features: [
            "Lines | Circles | Patterns | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/122314730001072020095936.jpg", // 9
          code: "RUSH00JJ",
          features: [
            "Lines | Squares | Geometric | Rectangles | Green | Red | Yellow | Pink | Orange | Patterns | See-Through | Seamless | Privacy | Nature | Flowers | Roses | Floral",
          ],
        },
        // new
        {
          image:
            "https://glassdesign.online/images/glassdesign/200787185730072020064430.jpg", // 9
          code: "SMBDYO3T",
          features: [
            "Lines | Squares | Patterns | Transparent | See-Through | Nature | Leaves | Flowers | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/84578909828072020111127.jpg", // 9
          code: "99ZLBRL8",
          features: [
            "Lines | Blue | Green | Brown | Patterns | Graidients | See-Through | Seamless | Privacy | Nature | Leaves | Flowers | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/80384253001072020063245.jpg", // 9
          code: "PCBZ9T1V",
          features: [
            "Lines | Squares | Diamonds | Geometric | Green | Grey | Patterns | Graidients | See-Through | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/32044872620032022123335.jpg", // 9
          code: "6BEI807F",
          features: [
            "Lines | Colors | Green | Yellow | Brown | Grey | Translucent | Graidients | Transparent | Leaves | Flowers | Vector | Floral | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/130149969722062020152128.jpg", // 9
          code: "N4Q66GXK",
          features: [
            "Green | Brown | Patterns | Graidients | See-Through | Seamless | Privacy | Nature | Leaves | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/158364946028072020103848.jpg", // 9
          code: "P85GYIAN",
          features: [
            "Lines | Squares | Geometric | Rectangles | Colors | Green | Pink | Patterns | Graidients | See-Through | Seamless | Privacy | Nature | Leaves | Flowers | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/4422321022062020151234.jpg", // 9
          code: "V5TJPGNK",
          features: [
            "Lines | Circles | Geometric | Colors | Blue | Black | Brown | Colorful | Patterns | Graidients | See-Through | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/81442581420032022141310.jpg", // 9
          code: "5TGICXPC",
          features: [
            "Lines | Dots | Rectangles | Colors | Blue | Green | Red | Brown | Pink | Colorful | Patterns | Graidients | See-Through | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/8271603028072020104352.jpg", // 9
          code: "IZE3DP28",
          features: [
            "Lines | Geometric | Rectangles | Colors | Blue | Grey | Patterns | Graidients | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/148873447330072020062606.jpg", // 9
          code: "FQVQ2Z3R",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/98814177322062020151959.jpg", // 9
          code: "O1EWHAKM",
          features: [
            "Diamonds | Geometric | Blue | Green | Grey | Patterns | Graidients | See-Through | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/185674458522062020151119.jpg", // 9
          code: "CTAMNCAM",
          features: [
            "Lines | Dots | Geometric | Rectangles | Colors | Green | Red | Brown | Purple | Pink | Colorful | Graidients | See-Through | Seamless | Privacy",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/185475072401072020094827.jpg", // 9
          code: "TD7DEU8O",
          features: [
            "Lines | Diamonds | Rectangles | Colors | Patterns | Graidients | See-Through | Seamless | Privacy | Nature | Leaves | Flowers | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/123109654201072020095251.jpg", // 9
          code: "X57P482T",
          features: [
            "Squares | Geometric | Rectangles | Blue | Green | Brown | Patterns | Graidients | See-Through | Seamless | Privacy | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/127762186529072020055904.jpg", // 9
          code: "R5OXOC4W",
          features: [
            "Lines | Circles | Diamonds | Geometric | Triangles | Black | Marble | Patterns | See-Through | Seamless | Privacy | Vector | Stone",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/4154007001072020123314.jpg", // 9
          code: "TWNEXTSJ",
          features: [
            "Lines | Squares | Rectangles | Colors | Blue | Green | Yellow | Brown | Orange | Flowers | Vector | Floral | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/98592450529072020095213.jpg", // 9
          code: "B5M72HQI",
          features: [
            "Lines | Circles | Diamonds | Geometric | Triangles | Black | Brown | Patterns | Graidients | See-Through | Seamless | Privacy | Net | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/83225710819042021083648.jpg", // 9
          code: "05ZOADPL",
          features: [
            "Lines | Dots | Circles | Patterns | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/153097617922062020152252.jpg", // 9
          code: "2SQUW1OE",
          features: [
            "Lines | Geometric | Blue | Brown | Grey | Patterns | Graidients | See-Through | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/179652112319042021094709.jpg", // 9
          code: "0PING5QD",
          features: [
            "Lines | Circles | Patterns | Seamless | Privacy | Vector",
          ],
        },
        // new
        {
          image:
            "https://glassdesign.online/images/glassdesign/37137374722062020150918.jpg", // 9
          code: "VKJJO927",
          features: [
            "Lines | Rectangles | Colors | Blue | Green | Red | Yellow | Brown | Pink | Orange | Colorful | Patterns | Graidients | See-Through | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/65445282228072020104548.jpg", // 9
          code: "Y2O5FN5M",
          features: [
            "Circles | Diamonds | Geometric | Yellow | Brown | Pink | Orange | Grey | Graidients | See-Through | Seamless | Privacy | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/161279009501072020094614.jpg", // 9
          code: "TPPMOJQQ",
          features: [
            "Lines | Squares | Geometric | Rectangles | Green | Red | Yellow | Brown | Pink | Orange | Patterns | Graidients | See-Through | Seamless | Privacy | Nature | Flowers | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/138453929819042021094850.jpg", // 9
          code: "8WURCXEJ",
          features: ["Lines | Patterns | Privacy | Vector"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/70610690129072020095343.jpg", // 9
          code: "ORU31VOX",
          features: [
            "Lines | Circles | Diamonds | Geometric | Triangles | Black | Brown | Patterns | See-Through | Seamless | Privacy | Net | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/2819138120032022113048.jpg", // 9
          code: "SKBO6XUI",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/48537243328072020110951.jpg", // 9
          code: "VDFUM4A6",
          features: [
            "Diamonds | Geometric | Green | Red | Patterns | See-Through | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/200758414430072020063639.jpg", // 9
          code: "T1M12W7G",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Patterns | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/105888603601072020092919.jpg", // 9
          code: "LZRPSMFT",
          features: [
            "Lines | Circles | Geometric | Colors | Green | Red | Brown | Pink | Colorful | Graidients | Infinity",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/100158193229072020094854.jpg", // 9
          code: "G7O39DEZ",
          features: [
            "Lines | Circles | Geometric | Triangles | Green | Brown | Marble | Patterns | Graidients | See-Through | Seamless | Privacy | Waves | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/98177483130072020101933.jpg", // 9
          code: "MOIFU76L",
          features: [
            "Grey | Marble | Cement | Surfaces | Opaque | Stone | Texture",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/196922513522062020151717.jpg", // 9
          code: "7CH58JJQ",
          features: [
            "Colors | Green | Red | Pink | Patterns | Graidients | See-Through | Seamless | Privacy | Nature | Leaves | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/164912169229072020055522.jpg", // 9
          code: "QNJUS5EM",
          features: [
            "Lines | Circles | Diamonds | Geometric | Triangles | Blue | Patterns | See-Through | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/177904190830072020065343.jpg", // 9
          code: "0A4KD4T2",
          features: [
            "Lines | Dots | Squares | Circles | Diamonds | Geometric | Patterns | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/152102872719042021094345.jpg", // 9
          code: "JHLELPZJ",
          features: [
            "Lines | Squares | Circles | Patterns | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/196193623422062020151830.jpg", // 9
          code: "C9PS8FI0",
          features: [
            "Brown | Patterns | Graidients | See-Through | Seamless | Privacy | Leaves | Flowers | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/20337055628072020103650.jpg", // 9
          code: "LVD34QWT",
          features: [
            "Diamonds | Geometric | Colors | Green | Brown | Pink | Arabian | Patterns | Graidients | See-Through | Seamless | Nature | Leaves | Flowers | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/88298398401072020095116.jpg", // 9
          code: "4JDR9VLL",
          features: [
            "Geometric | Pink | Patterns | Graidients | See-Through | Seamless | Privacy | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/212794479228072020110817.jpg", // 9
          code: "LPA9M6EC",
          features: [
            "Lines | Squares | Geometric | Rectangles | Colors | Green | Brown | Pink | Patterns | Graidients | See-Through | Seamless | Privacy | Leaves | Flowers | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/200389167622062020152458.jpg", // 9
          code: "XU2D4Z90",
          features: [
            "Lines | Squares | Circles | Rectangles | Colors | Green | Red | Yellow | Brown | Pink | Orange | Colorful | Patterns | Graidients | See-Through | Seamless | Privacy | Nature | Leaves | Vector",
          ],
        },
        // here .
        {
          image:
            "https://glassdesign.online/images/glassdesign/46043338222062020151544.jpg", // 9
          code: "1A1FGORK",
          features: [
            "Lines | Circles | Geometric | Colors | Blue | Green | Pink | Colorful | Patterns | Graidients | See-Through | Seamless | Privacy | Infinity | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/2118921524062020072858.jpg", // 9
          code: "BX2R45WQ",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/95571692528072020111620.jpg", // 9
          code: "DP8CK8P7",
          features: [
            "Lines | Squares | Geometric | Triangles | Blue | Patterns | Graidients | See-Through | Seamless | Privacy | Waves | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/90476286319042021094807.jpg", // 9
          code: "BMMB8F8Y",
          features: [
            "Lines | Diamonds | Patterns | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/7567770130072020063404.jpg", // 9
          code: "AZFAF3JR",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/23751848124062020073015.jpg", // 9
          code: "KT7G471T",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/111230793619042021094530.jpg", // 9
          code: "ZB6K50DQ",
          features: [
            "Lines | Dots | Circles | Patterns | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/108294470428072020104806.jpg", // 9
          code: "QMW13O8N",
          features: [
            "Diamonds | Green | Pink | Arabian | Patterns | Graidients | See-Through | Seamless | Privacy | Leaves | Flowers | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/1117313528072020112026.jpg", // 9
          code: "JUDKU2PO",
          features: [
            "Lines | Geometric | Triangles | Green | Brown | Patterns | Graidients | See-Through | Seamless | Privacy | Waves | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/94108640101072020095435.jpg", // 9
          code: "EGIT9QD2",
          features: [
            "Lines | Dots | Squares | Geometric | Rectangles | Green | Brown | Patterns | Graidients | See-Through | Seamless | Privacy | Leaves | Vector | White",
          ],
        },
        // here
        {
          image:
            "https://glassdesign.online/images/glassdesign/168026964828072020111429.jpg", // 9
          code: "6L81NHGG",
          features: [
            "Lines | Circles | Geometric | Colors | Green | Yellow | Patterns | Graidients | See-Through | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/113868775701072020122918.jpg", // 9
          code: "GYGUYCGB",
          features: [
            "Lines | Squares | Rectangles | Colors | Blue | Green | Black | Brown | Flowers | Roses | Vector | Floral | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/127889063501072020100504.jpg", // 9
          code: "I93IYY63",
          features: [
            "Green | Red | Pink | Patterns | See-Through | Opaque | Seamless | Privacy | Leaves | Vector | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/146900387619042021081343.jpg", // 9
          code: "AMDWSMO8",
          features: [
            "Lines | Squares | Patterns | Seamless | Privacy | Nature | Animal | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/94108640101072020095435.jpg", // 9
          code: "EGIT9QD2",
          features: [""],
        },
      ],
    },
    // {
    //   image: WALL_CLADDING,
    //   href: "wall_cladding",
    //   title: strings.WALL_CLADDING,
    //   description: strings.WALL_CLADDING_Description,
    //   images: [
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/58491755623062020105515.jpg",
    //       code: "FLIYXN85",
    //       features: [
    //         "Lines | Geometric | Colors | Green | Red | Yellow | Brown | Patterns | Leaves | Flowers | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/56683823429072020105432.jpg",
    //       code: "FQO496TD",
    //       features: [
    //         "Lines | Geometric | Black | Brown | Grey | Marble | Patterns | Surfaces | Opaque | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/45167677323062020105849.jpg",
    //       code: "YIWC0AFR",
    //       features: [
    //         "Lines | Squares | Diamonds | Geometric | Triangles | Green | Patterns | Opaque | Seamless | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/111117044829072020105553.jpg",
    //       code: "6M8QQMYQ",
    //       features: [
    //         "Lines | Blue | Green | Brown | Patterns | Privacy | Nature | Leaves | Flowers | Vector | Floral | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/48275568401072020104255.jpg",
    //       code: "SFPZZE3G",
    //       features: [
    //         "Lines | Squares | Geometric | Blue | Patterns | Leaves | Flowers | Vector | Floral | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/47097223502082020131201.jpg",
    //       code: "FVZ4RAPK",
    //       features: [
    //         "Lines | Red | Brown | Wood | Surfaces | Opaque | Nature | Dark | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/134222601530072020102521.jpg",
    //       code: "C5QRZYH0",
    //       features: [
    //         "Grey | Marble | Surfaces | Opaque | Dark | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/71565646230072020104655.jpg",
    //       code: "Y8V1BK45",
    //       features: [
    //         "Dark Blue | Marble | Surfaces | Opaque | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/145746872829072020105317.jpg",
    //       code: "4725MGSI",
    //       features: [
    //         "Lines | Dots | Circles | Diamonds | Geometric | Triangles | Colors | Blue | Black | Brown | Grey | Patterns | Nature | Flowers | Vines | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/95936325901072020103112.jpg",
    //       code: "V1D53V7U",
    //       features: [
    //         "Lines | Geometric | Colors | Yellow | Black | Brown | Orange | Grey | Patterns | Opaque | Seamless | Vector",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/92586835930072020130334.jpg",
    //       code: "28KPXF0D",
    //       features: [
    //         "Black | Grey | Marble | Surfaces | Opaque | Dark | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/208391324612072020123144.jpg",
    //       code: "WCZG4N7R",
    //       features: [
    //         "Lines | Grey | Cement | Patterns | Surfaces | Opaque | Tiles | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/86097105523062020105646.jpg",
    //       code: "TZWKFYPP",
    //       features: [
    //         "Lines | Dots | Geometric | Colors | Green | Red | Yellow | Brown | Patterns | Leaves | Flowers | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/10690525330072020102717.jpg",
    //       code: "4ADDEHZX",
    //       features: ["Grey | Marble | Surfaces | Opaque | Stone | Texture"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/182922587123062020110021.jpg",
    //       code: "T7R7TQZN",
    //       features: [
    //         "Lines | Circles | Geometric | Blue | Grey | Patterns | Leaves | Vector | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/206084359401072020102946.jpg",
    //       code: "ISMCRDN3",
    //       features: [
    //         "Lines | Squares | Geometric | Green | Yellow | Black | Patterns | Opaque | Seamless | Vector",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/35858459001072020103413.jpg",
    //       code: "W4WDSY2O",
    //       features: [
    //         "Lines | Squares | Geometric | Blue | Patterns | Leaves | Flowers | Vector | Floral | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/3427618712072020123038.jpg",
    //       code: "KC963GK8",
    //       features: [
    //         "Lines | Squares | Black | Grey | Marble | Patterns | Surfaces | Opaque",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/62294908814072020141255.jpg",
    //       code: "LILEU4TC",
    //       features: [
    //         "Dots | Circles | Colors | Green | Red | Black | Pink | Patterns | Opaque | Seamless | Leaves | Flowers | Vector | Floral | White          ",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/13930486514072020141237.jpg",
    //       code: "70EPI3QK",
    //       features: [
    //         "Lines | Squares | Geometric | Blue | Patterns | Leaves | Flowers | Vector | Floral | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/52511693923062020110556.jpg",
    //       code: "ARYJVSQV",
    //       features: [
    //         "Lines | Dots | Circles | Geometric | Green | Red | Black | Pink | Patterns | Opaque | Seamless | Flowers | Vector | Floral",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/148843673823062020110337.jpg",
    //       code: "QYSRVADE",
    //       features: [
    //         "Lines | Green | Red | Pink | Patterns | Opaque | Seamless | Nature | Leaves | Flowers | Floral | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/67406369802082020122104.jpg",
    //       code: "65VVBEOU",
    //       features: [
    //         "Lines | Blue | Grey | Dark Blue | Wood | Surfaces | Opaque | Nature | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/130122136002082020120519.jpg",
    //       code: "9UVN24NU",
    //       features: ["Marble | Sand | Surfaces | Opaque | Stone | Texture"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/34310887102082020115019.jpg",
    //       code: "CU4RC2EP",
    //       features: [
    //         "Squares | Marble | Surfaces | Opaque | White | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/208163971523062020093809.jpg",
    //       code: "JRTIE3CM",
    //       features: [
    //         "Squares | Colors | Green | Pink | Patterns | Opaque | Seamless | Leaves | Flowers | Vector | Floral | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/95597642929072020105042.jpg",
    //       code: "WEKI0UZW",
    //       features: [
    //         "Lines | Circles | Geometric | Triangles | Blue | Black | Vintage | Nature | Leaves | Flowers | Floral",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/68687247112072020123118.jpg",
    //       code: "WHZ5VPJ9",
    //       features: [
    //         "Lines | Dots | Ornamants | Blue | Grey | Cement | Patterns | Surfaces | Opaque | Flowers | White | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/40396948801072020103713.jpg",
    //       code: "YT21AU1K",
    //       features: [
    //         "Lines | Squares | Geometric | Triangles | Blue | Black | Grey | Patterns | Opaque | Seamless | Waves | Vector",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/134754348601072020103249.jpg",
    //       code: "5NE3SP74",
    //       features: [
    //         "Lines | Squares | Geometric | Yellow | Pink | Patterns | Opaque | Seamless | Leaves | Flowers | Vector | Floral | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/53560708606072020111959.jpg",
    //       code: "U5CTK1KV",
    //       features: [
    //         "Lines | Purple | Pink | Grey | Colorful | Cement | Patterns | Surfaces | Opaque | Tiles | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/88960629130072020140830.jpg",
    //       code: "JZ2MY2SH",
    //       features: [
    //         "Pink | Marble | Surfaces | Opaque | Bright | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/34155679501072020103540.jpg",
    //       code: "OW6XQ8K0",
    //       features: [
    //         "Lines | Squares | Colors | Blue | Green | Brown | Pink | Patterns | Opaque | Seamless | Leaves | Flowers | Vector | Rope | Floral | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/191692065529072020110141.jpg",
    //       code: "YT21AU1K",
    //       features: [
    //         "Lines | Dots | Geometric | Brown | Marble | Patterns | Surfaces | Privacy | Nature | Leaves | Flowers | White | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/164751783923062020093747.jpg",
    //       code: "YD1CMMBC",
    //       features: [
    //         "Colors | Green | Brown | Pink | Patterns | Opaque | Seamless | Leaves | Vector | Floral",
    //       ],
    //     },
    //   ],
    // },

    // {
    //   image: WOOD,
    //   href: "WOOD",
    //   title: strings.WOOD,
    //   description: strings.woodDescription,
    //   images: [
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/80041817123062020113913.jpg", // 9
    //       code: "687B381I",
    //       features: [
    //         "Squares | Red | Brown | Orange | Opaque | Nature | Trees",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/172376877023062020113629.jpg", // 9
    //       code: "RACIO766",
    //       features: ["Red | Brown | Orange | Opaque | Nature | Trees"],
    //     },
    //     {
    //       image:
    //         "	https://glassdesign.online/images/glassdesign/204771201030072020105033.jpg", // 9
    //       code: "1DEP1P6U",
    //       features: [
    //         "Lines | Brown | Surfaces | Opaque | Nature | Dark | Trees | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/160981869002082020130037.jpg", // 9
    //       code: "O2UXHDMZ",
    //       features: [
    //         "Dots | Red | Brown | Marble | Surfaces | Opaque | Nature | Dark | Trees | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/193983125830072020105312.jpg", // 9
    //       code: "QAEMV9U7",
    //       features: [
    //         "Lines | Brown | Surfaces | Opaque | Nature | Trees | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/22516269927072020075247.jpg", // 9
    //       code: "125EUU1K",
    //       features: ["Brown | Opaque | Nature | Trees"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/102324686123062020114123.jpg", // 9
    //       code: "C52GLQ61",
    //       features: ["Brown | Opaque | Nature | Trees"],
    //     },
    //     {
    //       image:
    //         "	https://glassdesign.online/images/glassdesign/12796313627072020075314.jpg", // 9
    //       code: "W8X6P831",
    //       features: ["Squares | Brown | Opaque | Nature | Trees | Tiles"],
    //     },
    //     {
    //       image:
    //         "	https://glassdesign.online/images/glassdesign/21155106230072020104907.jpg", // 9
    //       code: "DYBMGSF4",
    //       features: [
    //         "Lines | Black | Grey | Surfaces | Opaque | Nature | Trees | White | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/78495931102082020114841.jpg", // 9
    //       code: "HL1FZRS0",
    //       features: [
    //         "Lines | Dots | Brown | Surfaces | Nature | Trees | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/105078305514072020123600.jpg", // 9
    //       code: "WDKTWGLV",
    //       features: ["Brown | Wood | Patterns | Opaque | Gold | Round"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/166153072830072020111842.jpg", // 9
    //       code: "NHDIY766",
    //       features: [
    //         "Lines | Brown | Opaque | Nature | Dark | Trees | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/189438017223062020115358.jpg", // 9
    //       code: "8KANPOH",
    //       features: ["Brown | Opaque | Nature | Trees"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/159689222123062020114645.jpg", // 9
    //       code: "X10WTJR9",
    //       features: ["Brown | Grey | Opaque"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/85334738923062020114514.jpg", // 9
    //       code: "4KIQMHZM",
    //       features: ["Squares | Brown | Orange | Opaque | Trees"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/89253887927072020075348.jpg", // 9
    //       code: "9RCQ1VE8",
    //       features: ["Opaque | Nature | Trees | Tiles"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/30326848227072020075519.jpg", // 9
    //       code: "NF6D6YS7",
    //       features: ["Brown | Opaque | Tiles"],
    //     },
    //     {
    //       image:
    //         "	https://glassdesign.online/images/glassdesign/74287221630072020130633.jpg", // 9
    //       code: "Q5TDPZQB",
    //       features: [
    //         "Lines | Brown | Surfaces | Opaque | Nature | Bright | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/194628836730072020105158.jpg", // 9
    //       code: "1G13H7CK",
    //       features: [
    //         "Lines | Black | Grey | Surfaces | Opaque | Nature | Dark | Trees | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/167907734830072020112024.jpg", // 9
    //       code: "Z2KQ6EC5",
    //       features: ["Lines | Brown | Surfaces | Opaque | Nature | Texture"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/67406369802082020122104.jpg", // 9
    //       code: "65VVBEOU",
    //       features: [
    //         "Lines | Blue | Grey | Dark Blue | Wood | Surfaces | Opaque | Nature | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/47097223502082020131201.jpg", // 9
    //       code: "FVZ4RAPK",
    //       features: [
    //         "Lines | Red | Brown | Wood | Surfaces | Opaque | Nature | Dark | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/32512862002082020133215.jpg", // 9
    //       code: "RZDFE8M4",
    //       features: [
    //         "Lines | Green | Grey | Wood | Surfaces | Opaque | Nature | Dark | Trees | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/19700415802082020124830.jpg", // 9
    //       code: "JDNT34C5",
    //       features: [
    //         "Lines | Squares | Brown | Pink | Surfaces | Opaque | Nature | Trees | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/138613785323062020114024.jpg", // 9
    //       code: "ZSDDNPHO",
    //       features: ["Brown | Opaque | Nature | Trees"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/167344392030072020130511.jpg", // 9
    //       code: "O2UXHDMZ",
    //       features: [
    //         "Red | Marble | Surfaces | Opaque | Dark | Stone | Texture",
    //       ],
    //     },
    //   ],
    // },

    // {
    //   image: STONE,
    //   href: "STONE",
    //   title: strings.STONE,
    //   description: strings.stoneDescription,
    //   images: [
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/45282339801072020132209.jpg", // 1
    //       code: "2ZYYQICG",
    //       features: [
    //         "Cracks | Black | Grey | Marble | Surfaces | Opaque | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/53560708606072020111959.jpg", // 1
    //       code: "U5CTK1KV",
    //       features: [
    //         "Lines | Purple | Pink | Grey | Colorful | Cement | Patterns | Surfaces | Opaque | Tiles | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/14633702714072020135551.jpg", // 1
    //       code: "IJTRICJ0",
    //       features: ["Lines | Yellow | Black | Marble | Patterns | Opaque"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/98177483130072020101933.jpg", // 1
    //       code: "MOIFU76L",
    //       features: [
    //         "Grey | Marble | Cement | Surfaces | Opaque | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/23486599230072020102356.jpg", // 1
    //       code: "SRSI22E4",
    //       features: [
    //         "Black | Dark Blue | Marble | Surfaces | Opaque | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/193036356401072020135050.jpg", // 1
    //       code: "NZX126OY",
    //       features: ["Brown | Grey | Opaque"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/61457390901072020134913.jpg", // 1
    //       code: "JT8SNFY4",
    //       features: ["Brown | Grey | Cement | Opaque"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/130452978329072020141904.jpg", // 1
    //       code: "C8LM4PWD",
    //       features: [
    //         "Lines | Cracks | Black | Grey | Marble | Surfaces | Opaque | White | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/70329814329072020141054.jpg", // 1
    //       code: "FS2FAW11",
    //       features: ["Red | Onyx | Surfaces | Opaque | White"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/116783357229072020143036.jpg", // 1
    //       code: "57H3UWQ1",
    //       features: ["Brown | Orange | Surfaces | Opaque | Tiles | Stone"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/167503788124062020080136.jpg", // 1
    //       code: "AT9T9XRO",
    //       features: [
    //         "Lines | Black | Brown | Grey | Marble | Surfaces | Opaque | White | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/140642925701072020134600.jpg", // 1
    //       code: "2UIDTFF2",
    //       features: ["Cracks | Grey | Cement | Opaque"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/88709835801072020133119.jpg", // 1
    //       code: "JZN0El19",
    //       features: ["Green | Brown | Orange | Marble | Opaque"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/34310887102082020115019.jpg", // 1
    //       code: "CU4RC2EP",
    //       features: [
    //         "Squares | Marble | Surfaces | Opaque | White | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/65718482920092022144012.jpg", // 1
    //       code: "OO7D3A52 ",
    //       features: [
    //         "Yellow | Orange | Grey | Cement | Fade | Opaque | Nature | Plants | Earth | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/22976080724062020075818.jpg", // 1
    //       code: "JIJBLMER",
    //       features: ["Brown | Marble | Surfaces | Opaque | Stone"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/186843665524062020080322.jpg", // 1
    //       code: "LOXD3QIX",
    //       features: [
    //         "Lines | Squares | Cracks | Black | Grey | Marble | Surfaces | Opaque | White | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/71565646230072020104655.jpg", // 1
    //       code: "Y8V1BK45",
    //       features: [
    //         "Dark Blue | Marble | Surfaces | Opaque | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/66620900114072020125842.jpg", // 1
    //       code: "6AHABURB",
    //       features: ["Cracks | Black | Grey | Marble | Opaque | White"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/17599439630072020101711.jpg", // 1
    //       code: "IGC1JDUO",
    //       features: [
    //         "Black | Marble | Surfaces | Opaque | Dark | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/143454616201072020131736.jpg", // 1
    //       code: "DB1FLI9U",
    //       features: ["Blue | Brown | Sand | Opaque | White"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/163967608502082020113702.jpg", // 1
    //       code: "0SMFEJOI",
    //       features: [
    //         "Black | Marble | Surfaces | Opaque | Dark | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/59053251329072020141725.jpg", // 1
    //       code: "PEJZIM26",
    //       features: [
    //         "Lines | Cracks | Grey | Marble | Surfaces | Opaque | White | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/20032534901072020133017.jpg", // 1
    //       code: "5l5SBA0Q",
    //       features: ["Lines | Blue | Purple | Grey | Marble | Opaque"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/212242643501072020131446.jpg", // 1
    //       code: "TM42A4MV",
    //       features: ["Black | Grey | Opaque | White | Tiles"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/156444403202082020113823.jpg", // 1
    //       code: "82U45KPP",
    //       features: ["Cracks | Marble | Surfaces | White | Stone | Texture"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/185726388830072020100728.jpg", // 1
    //       code: "47Z2XCSD",
    //       features: [
    //         "Lines | Marble | Surfaces | Opaque | Earth | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/47820808729072020142113.jpg", // 1
    //       code: "VIR45466",
    //       features: [
    //         "Lines | Cracks | Black | Brown | Grey | Marble | Surfaces | Opaque | White | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/179982954001072020132525.jpg", // 1
    //       code: "98US47X5",
    //       features: ["Red | Brown | Orange | Marble | White"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/50374232901072020134408.jpg", // 1
    //       code: "FQPVLOQU",
    //       features: ["Grey | Cement | Opaque"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/45649352229072020143209.jpg", // 1
    //       code: "G636B204",
    //       features: ["Grey | Surfaces | Opaque | Stone"],
    //     },

    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/196297878920092022142339.jpg", // 1
    //       code: "1ZKIBS5U",
    //       features: [
    //         "Blue | Green | Grey | Cement | Opaque | Nature | Earth | Mountains | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/91319219524062020080515.jpg", // 1
    //       code: "4QL82LF8",
    //       features: ["Green | Yellow | Grey | Marble | Onyx | Opaque | White"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/183309606220092022141604.jpg", // 1
    //       code: "9C4EELR9",
    //       features: [
    //         "Green | Red | Pink | Colorful | Cement | Graidients | Opaque | Nature | Flowers | Plants | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/92586835930072020130334.jpg", // 1
    //       code: "28KPXF0D",
    //       features: [
    //         "Black | Grey | Marble | Surfaces | Opaque | Dark | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/122822375101072020132620.jpg", // 1
    //       code: "XIW3TWG5",
    //       features: ["Orange | Marble | Sand | Opaque | White"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/70619555824062020075248.jpg", // 1
    //       code: "DEO5X7G7",
    //       features: [
    //         "Brown | Orange | Grey | Marble | Sand | Surfaces | Opaque | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/130122136002082020120519.jpg", // 1
    //       code: "9UVN24NU",
    //       features: ["Marble | Sand | Surfaces | Opaque | Stone | Texture"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/142777735130072020101502.jpg", // 1
    //       code: "CTSSRMP9",
    //       features: [
    //         "Turquoise | Marble | Surfaces | Opaque | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/179252102014072020123639.jpg", // 1
    //       code: "RR9GFATA",
    //       features: ["Lines | Blue | Black | Grey | Marble | Onyx | Opaque"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/212142751029072020143401.jpg", // 1
    //       code: "GX3BFWKK",
    //       features: ["Circles | Grey | Surfaces | Opaque"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/208065001824062020075356.jpg", // 1
    //       code: "XXX2T61E",
    //       features: [
    //         "Squares | Yellow | Brown | Grey | Marble | Surfaces | Opaque | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/117700459429072020142659.jpg", // 1
    //       code: "RACLJZ3P",
    //       features: ["Brown | Orange | Opaque | White | Stone"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/101153076601072020135309.jpg", // 1
    //       code: "EP6TA1N3",
    //       features: ["Grey | Cement | Opaque"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/160765403201072020131619.jpg", // 1
    //       code: "I3E4J0NG",
    //       features: ["Black | Grey | Opaque | White"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/156633813029072020143504.jpg", // 1
    //       code: "RRK65IOF",
    //       features: ["Squares | Grey | Surfaces | Opaque | White | Tiles"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/84924679230072020102051.jpg", // 1
    //       code: "6T5WCF8U",
    //       features: [
    //         "Grey | Marble | Surfaces | Opaque | Dark | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/98363143101072020133923.jpg", // 1
    //       code: "AG31RBN7",
    //       features: ["Yellow | Orange | Grey | Marble | Opaque | White"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/78987862514072020133707.jpg", // 1
    //       code: "WQUM9A6J",
    //       features: ["Grey | Cement | Opaque | White"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/151273600824062020075123.jpg", // 1
    //       code: "Z4Y8NSB8",
    //       features: [
    //         "Squares | Brown | Pink | Grey | Marble | Surfaces | Opaque | Stone",
    //       ],
    //     },
    //   ],
    // },

    // {
    //   image: TEXTURE,
    //   href: "TEXTURE",
    //   title: strings.TEXTURE,
    //   description: strings.textureDescription,
    //   images: [
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/165524054806072020112705.jpg", // 4
    //       code: "VJ2H8XM9",
    //       features: [
    //         "Lines | Squares | Circles | Blue | Grey | Cement | Patterns | Surfaces | Opaque | White | Stone",
    //       ],
    //     },

    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/200174659312072020123235.jpg", // 4
    //       code: "FTKLHCZB",
    //       features: [
    //         "Lines | Squares | Black | Grey | Cement | Patterns | Surfaces | Opaque | Nature | White | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/198771251912072020123012.jpg", // 4
    //       code: "5IYSEM94",
    //       features: ["Lines | Diamonds | Black | Grey | Surfaces | Opaque"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/183309606220092022141604.jpg", // 4
    //       code: "9C4EELR9",
    //       features: [
    //         "Green | Red | Pink | Colorful | Cement | Graidients | Opaque | Nature | Flowers | Plants | Stone | Texture",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/112341906072020110039.jpg", // 4
    //       code: "I0LZHXBC",
    //       features: [
    //         "Lines | Squares | Grey | Cement | Patterns | Surfaces | Opaque | White",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/208391324612072020123144.jpg", // 4
    //       code: "WCZG4N7R",
    //       features: [
    //         "Lines | Grey | Cement | Patterns | Surfaces | Opaque | Tiles | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/17788298612072020123210.jpg", // 4
    //       code: "KIW7HHXX",
    //       features: [
    //         "Lines | Purple | Pink | Grey | Colorful | Cement | Patterns | Surfaces | Opaque | Tiles | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/119745361906072020112512.jpg", // 4
    //       code: "S1E4OA6F",
    //       features: [
    //         "Lines | Circles | Orange | Grey | Colorful | Cement | Patterns | Surfaces | Opaque | Tiles | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/113709176512072020123058.jpg", // 4
    //       code: "7C7LT8NF",
    //       features: [
    //         "Lines | Ornamants | Blue | Grey | Cement | Patterns | Surfaces | Opaque | Flowers | White | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/3427618712072020123038.jpg", // 4
    //       code: "KC963GK8",
    //       features: [
    //         "Lines | Squares | Black | Grey | Marble | Patterns | Surfaces | Opaque",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/199413684906072020114033.jpg", // 4
    //       code: "RPMUYCA9",
    //       features: [
    //         "Lines | Squares | Black | Grey | Cement | Patterns | Surfaces | Opaque | Flowers | White | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/68687247112072020123118.jpg", // 4
    //       code: "WHZ5VPJ9",
    //       features: [
    //         "Lines | Dots | Ornamants | Blue | Grey | Cement | Patterns | Surfaces | Opaque | Flowers | White | Stone",
    //       ],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/30230192224062020080855.jpg", // 4
    //       code: "IRRTBJ5M",
    //       features: ["Brown | Orange | Opaque"],
    //     },
    //     {
    //       image:
    //         "https://glassdesign.online/images/glassdesign/53560708606072020111959.jpg", // 4
    //       code: "U5CTK1KV",
    //       features: [
    //         "Lines | Purple | Pink | Grey | Colorful | Cement | Patterns | Surfaces | Opaque | Tiles | Stone",
    //       ],
    //     },
    //   ],
    // },

    {
      image: PATTERNS,
      href: "PATTERNS",
      title: strings.PATTERNS,
      description: strings.patternDescription,
      images: [
        {
          image:
            "https://glassdesign.online/images/glassdesign/177904190830072020065343.jpg", // 1
          code: "0A4KD4T2",
          features: [
            "Lines | Dots | Squares | Circles | Diamonds | Geometric | Patterns | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/30761875730072020064123.jpg", // 2
          code: "KRR4CO58",
          features: [
            "Lines | Squares | Patterns | Nature | Leaves | Flowers | Waves | Sea | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/200758414430072020063639.jpg", // 3
          code: "T1M12W7G",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Patterns | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/26851448030072020064820.jpg", // 4
          code: "LP8LX096",
          features: [
            "Lines | Dots | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/106763318829072020145122.jpg",
          code: "TE6KJ7LA",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/102903283522032022115238.jpg", // 6
          code: "DQLOYSQ5",
          features: [
            "Lines | Dots | Squares | Circles | Diamonds | Geometric | Patterns | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/168493185524062020072709.jpg", // 6
          code: "4XAHRF65",
          features: [
            "Lines | Dots | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/118981627230072020063805.jpg", // 6
          code: "DKZCIMA1",
          features: [
            "Lines | Squares | Patterns | See-Through | Nature | Leaves | Flowers | Waves | Sea | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/7567770130072020063404.jpg", // 6
          code: "AZFAF3JR ",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/2118921524062020072858.jpg", // 6
          code: "BX2R45WQ",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/200787185730072020064430.jpg", // 6
          code: "SMBDYO3T",
          features: [
            "Lines | Squares | Patterns | Transparent | See-Through | Nature | Leaves | Flowers | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/1468261427102022132841.jpg", // 6
          code: "MS0JBL95",
          features: [
            "Lines | Geometric | Criss-Cross | Black | Patterns | See-Through | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/10982716030072020064630.jpg", // 6
          code: "Y4F7DEW3",
          features: [
            "Lines | Dots | Squares | Circles | Diamonds | Geometric | Patterns | See-Through | Seamless | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/37560303524062020072540.jpg", // 6
          code: "AYLPB20E",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/2819138120032022113048.jpg", // 6
          code: "SKBO6XUI",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/148873447330072020062606.jpg", // 6
          code: "FQVQ2Z3R",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/23751848124062020073015.jpg", // 6
          code: "KT7G471T",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless",
          ],
        },
      ],
    },

    {
      image: BIRD_SAFE_PATTERNS,
      href: "BIRD_SAFE_PATTERNS",
      title: strings.BIRD_SAFE_PATTERNS,
      description: strings.BIRD_SAFE_PATTERNS_DESCRIPTION,
      images: [
        {
          image:
            "https://glassdesign.online/images/glassdesign/4438283728012021085657.jpg", // 1
          code: "R7Q79712",
          features: [
            "Lines | Circles | Black | Patterns | See-Through | Seamless | Nature | Flowers | Vector | Floral | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/191316936428012021085046.jpg", // 2
          code: "X8EFHO50",
          features: [
            "Lines | Black | Patterns | See-Through | Seamless | Nature | Flowers | Vector | Floral | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/204862615728012021084716.jpg", // 3
          code: "7O0FNXOB",
          features: [
            "Lines | Squares | Black | Patterns | See-Through | Seamless | Nature | Flowers | Vector | Floral | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/89838700927012021155431.jpg", // 4
          code: "SYX76DXJ",
          features: [
            "Lines | Black | Patterns | See-Through | Seamless | Nature | Animal | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/86252976728012021085541.jpg",
          code: "WSYIF6OT",
          features: [
            "Lines | Black | Patterns | See-Through | Seamless | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/58805402128012021085248.jpg", // 6
          code: "GL6UVG89",
          features: [
            "Lines | Black | Patterns | See-Through | Seamless | Nature | Animal | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/23648469728012021084904.jpg", // 7
          code: "272WZR1X",
          features: [
            "Lines | Colors | Black | Patterns | See-Through | Seamless | Nature | Flowers | Vector | Floral | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/94317331427012021155209.jpg", // 8
          code: "X4BDQC8K",
          features: [
            "Lines | Squares | Black | Patterns | See-Through | Seamless | Nature | Animal | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/82857687427012021153410.jpg", // 9
          code: "4YI08WPV",
          features: [
            "Lines | Squares | Black | Patterns | See-Through | Seamless | Nature | Animal | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/186413953527012021155717.jpg", // 10
          code: "XN3JX4NE",
          features: [
            "Lines | Black | Patterns | See-Through | Seamless | Nature | Animal | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/195387154827012021160406.jpg", // 10
          code: "GE5MRIKU",
          features: [
            "Lines | Black | Patterns | Seamless | Nature | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/151869950427012021155917.jpg", // 10
          code: "133YK3B9",
          features: [
            "Lines | Black | Patterns | See-Through | Seamless | Animal | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/196681936328012021084401.jpg", // 10
          code: "MDFXMZW4",
          features: [
            "Lines | Black | Patterns | See-Through | Seamless | Nature | Flowers | Vector | Floral | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/19230115628012021085433.jpg", // 10
          code: "H82VHOE5",
          features: [
            "Lines | Triangles | Colors | Blue | Green | Red | Black | Purple | Pink | Orange | Patterns | See-Through | Seamless | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/111743389827012021153301.jpg", // 10
          code: "2K6S3G7J",
          features: [
            "Lines | Black | Patterns | See-Through | Seamless | Nature | Animal | Vector | White",
          ],
        },
      ],
    },

    {
      image: GOLD_PATTERNS,
      href: "GOLD_PATTERNS",
      title: strings.GOLD_PATTERNS,
      images: [
        {
          image:
            "https://glassdesign.online/images/glassdesign/83225710819042021083648.jpg", // 1
          code: "BMMB8F8Y",
          features: [
            "Lines | Diamonds | Patterns | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/911220819042021094433.jpg", // 2
          code: "F2D9KWTV",
          features: ["Lines | Patterns | Seamless | Privacy | Vector"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/138453929819042021094850.jpg", // 3
          code: "8WURCXEJ",
          features: ["Lines | Patterns | Privacy | Vector"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/111230793619042021094530.jpg", // 4
          code: "ZB6K50DQ",
          features: [
            "Lines | Dots | Circles | Patterns | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/146900387619042021081343.jpg",
          code: "AMDWSMO8",
          features: [
            "Lines | Squares | Patterns | Seamless | Privacy | Nature | Animal | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/83225710819042021083648.jpg", // 6
          code: "05ZOADPL",
          features: [
            "Lines | Dots | Circles | Patterns | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/192278139819042021083806.jpg", // 7
          code: "CFFURDFQ",
          features: [
            "Lines | Circles | Patterns | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/152102872719042021094345.jpg", // 8
          code: "JHLELPZJ",
          features: [
            "Lines | Squares | Circles | Patterns | Seamless | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/179652112319042021094709.jpg", // 9
          code: "0PING5QD",
          features: [
            "Lines | Circles | Patterns | Seamless | Privacy | Vector",
          ],
        },
      ],
    },
    {
      image: PvbLaminationFilm,
      href: "pvb-lamination-film",
      title: strings.pvbLaminationFilm,
      description: strings.pvbLaminationFilmDescription,
      images: [
        {
          image: PvbLaminationFilm2,
          code: "PVB Lamination",
        },
        {
          image: PvbLaminationFilm3,
          code: "PVB Lamination",
        },
      ],
    },
    {
      image: soundControlInterLayers,
      href: "sound-control-interlayer",
      title: strings.soundControlInterlayer,
      // description: strings.pvbLaminationFilmDescription,
      images: [
        {
          image: soundControlInterLayers,
          code: "Sound Control Interlayer",
        },
        {
          image: soundControlInterLayers2,
          code: "Sound Control Interlayer",
        },
        {
          image: soundControlInterLayers3,
          code: "Sound Control Interlayer",
        },
        {
          image: soundControlInterLayers4,
          code: "Sound Control Interlayer",
        },
      ],
    },
    {
      image: structuralAndSecurityInterlayers,
      href: "structural-and-security-interlayers",
      title: strings.structuralAndSecurityInterlayers,
      // description: strings.pvbLaminationFilmDescription,
      images: [
        {
          image: structuralAndSecurityInterlayers2,
          code: "Structural And Security Interlayers",
        },
        {
          image: structuralAndSecurityInterlayers3,
          code: "Structural And Security Interlayers",
        },
        {
          image: structuralAndSecurityInterlayers4,
          code: "Structural And Security Interlayers",
        },
      ],
    },
    {
      image: COUNTER_TOPS,
      href: "counter-tops",
      title: strings.COUNTER_TOPS,
      description: strings.COUNTER_TOPS,
      images: [
        {
          image:
            "https://glassdesign.online/images/glassdesign/16072029723062020122934.jpg",
          code: "FCR0YK94",
          description: [
            "Rectangles | Blue | Green | Orange | Patterns | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/198731427923062020123124.jpg",
          code: "TBKTRNXG",
          description: [
            "Rectangles | Green | Yellow | Black | Marble | Patterns | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/48029102223062020123514.jpg",
          code: "MCLTALB2",
          description: [
            "Rectangles | Green | Brown | Grey | Patterns | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/45282339801072020132209.jpg",
          code: "2ZYYQICG",
          description: [
            "Cracks | Black | Grey | Marble | Surfaces | Opaque | Stone",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/199836977423062020123623.jpg",
          code: "962VO1SH",
          description: [
            "Lines | Rectangles | Black | Brown | Grey | Opaque | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/70868815023062020123358.jpg",
          code: "Z685NLF6",
          description: [
            "Lines | Squares | Rectangles | Green | Brown | Grey | Opaque | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/22976080724062020075818.jpg",
          code: "JIJBLMER",
          description: ["Brown | Marble | Surfaces | Opaque | Stone"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/30230192224062020080855.jpg",
          code: "IRRTBJ5M",
          description: ["Brown | Orange | Opaque"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/199144459323062020123819.jpg",
          code: "MWXY7UHC",
          description: [
            "Rectangles | Colors | Blue | Yellow | Brown | Purple | Orange | Grey | Patterns | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/78495931102082020114841.jpg",
          code: "HL1FZRS0",
          description: [
            "Lines | Dots | Brown | Surfaces | Nature | Trees | Texture",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/208065001824062020075356.jpg",
          code: "XXX2T61E",
          description: [
            "Squares | Yellow | Brown | Grey | Marble | Surfaces | Opaque | Stone",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/212030827001072020131849.jpg",
          code: "V8IYPVBE",
          description: ["Rectangles | Grey | Opaque | White"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/116783357229072020143036.jpg",
          code: "57H3UWQ1",
          description: ["Brown | Orange | Surfaces | Opaque | Tiles | Stone"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/111133593623062020123024.jpg",
          code: "RFH68EZZ",
          description: [
            "Lines | Rectangles | Green | Brown | Orange | Patterns | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/167344392030072020130511.jpg",
          code: "O2UXHDMZ",
          description: [
            "Red | Marble | Surfaces | Opaque | Dark | Stone | Texture",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/32512862002082020133215.jpg",
          code: "RZDFE8M4",
          description: [
            "Lines | Green | Grey | Wood | Surfaces | Opaque | Nature | Dark | Trees | Texture",
          ],
        },
      ],
    },
    {
      image: TABLE_TOPS,
      title: strings.TABLE_TOPS,
      description: strings.TABLE_TOPS,
      images: [
        {
          image:
            "https://glassdesign.online/images/glassdesign/92332757901072020115325.jpg",
          code: "IJXGSQFC",
          description: ["Red | Opaque | Nature | Flowers | Floral"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/209014974614072020110526.jpg",
          code: "LAL1OYG2",
          description: [
            "Squares | Pixels | Pixelation | Red | Orange | Opaque | Flowers | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/10730275114072020140917.jpg",
          code: "GHW7FD5R",
          description: [
            "Squares | Pixels | Blue | Green | Black | Turquoise | Flowers",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/64997227314072020140719.jpg",
          code: "J14O5NVN",
          description: [
            "Squares | Diamonds | Pixels | Green | Red | Pink | Graidients | Opaque | Flowers | Roses | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/117700459429072020142659.jpg",
          code: "RACLJZ3P",
          description: ["Brown | Orange | Opaque | White | Stone"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/106324292714072020112952.jpg",
          code: "1PUQOEA4",
          description: ["Lines | Black | Grey | Opaque | White | Round"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/88709835801072020133119.jpg",
          code: "JZN0El19",
          description: ["Green | Brown | Orange | Marble | Opaque"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/138895166029072020123200.jpg",
          code: "8M2U6OMM",
          description: [
            "Lines | Circles | Diamonds | Triangles | Green | Yellow | Graidients | Opaque",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/101153076601072020135309.jpg",
          code: "EP6TA1N3",
          description: ["Grey | Cement | Opaque"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/27282098429072020123346.jpg",
          code: "ZS1RH5HL",
          description: [
            "Lines | Circles | Diamonds | Geometric | Triangles | Green | Yellow | Graidients | Opaque | Gold",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/78987862514072020133707.jpg",
          code: "WQUM9A6J",
          description: ["Grey | Cement | Opaque | White"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/208582618114072020104805.jpg",
          code: "ZC9IMU2B",
          description: [
            "Squares | Pixels | Green | Black | Dark Blue | Opaque | Flowers | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/38001765329072020125051.jpg",
          code: "XJKX7BNR",
          description: [
            "Lines | Dots | Circles | Diamonds | Geometric | Triangles | Red | Opaque",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/160765403201072020131619.jpg",
          code: "I3E4J0NG",
          description: ["Black | Grey | Opaque | White"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/143580558014072020130204.jpg",
          code: "SXS7YE47",
          description: [
            "Lines | Squares | Diamonds | Black | Grey | Graidients | Opaque | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/130452978329072020141904.jpg",
          code: "C8LM4PWD",
          description: [
            "Lines | Cracks | Black | Grey | Marble | Surfaces | Opaque | White | Stone",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/104547841914072020134519.jpg",
          code: "0733NTJ1",
          description: [
            "Squares | Pixels | Red | Pink | Opaque | Flowers | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/14633702714072020135551.jpg",
          code: "IJTRICJ0",
          description: ["Lines | Yellow | Black | Marble | Patterns | Opaque"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/189895851929072020124926.jpg",
          code: "XJKX7BNR",
          description: [
            "Lines | Squares | Circles | Diamonds | Triangles | Green | Opaque",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/18561757814072020111406.jpg",
          code: "L67O8OMZ",
          description: [
            "Dots | Squares | Pixels | Green | Black | Flowers | Dark | Roses | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/212142751029072020143401.jpg",
          code: "GX3BFWKK",
          description: ["Circles | Grey | Surfaces | Opaque"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/141510298514072020135126.jpg",
          code: "27BTNSD6",
          description: [
            "Circles | Black | Grey | Graidients | Opaque | White | Round",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/135669101701072020131324.jpg",
          code: "E5I9X2MK",
          description: ["Squares | Brown | Sand | Opaque | White | Tiles"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/84246512629072020124801.jpg",
          code: "BYQJWOPG",
          description: [
            "Lines | Squares | Circles | Diamonds | Geometric | Triangles | Blue | Opaque",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/98363143101072020133923.jpg",
          code: "AG31RBN7",
          description: ["Yellow | Orange | Grey | Marble | Opaque | White"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/20032534901072020133017.jpg",
          code: "5l5SBA0Q",
          description: ["Lines | Blue | Purple | Grey | Marble | Opaque"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/34417850714072020134457.jpg",
          code: "ED921K1S",
          description: [
            "Squares | Pixels | Pixelation | Pink | Opaque | Flowers | Floral | White | Round",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/179252102014072020123639.jpg",
          code: "RR9GFATA",
          description: ["Lines | Blue | Black | Grey | Marble | Onyx | Opaque"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/119326155024062020070324.jpg",
          code: "2645YWP6",
          description: ["Squares | Pixels | Red | Orange | Flowers | Floral"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/88709835801072020133119.jpg",
          code: "JZN0El19",
          description: ["Green | Brown | Orange | Marble | Opaque"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/66620900114072020125842.jpg",
          code: "6AHABURB",
          description: ["Cracks | Black | Grey | Marble | Opaque | White"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/140642925701072020134600.jpg",
          code: "2UIDTFF2",
          description: ["Cracks | Grey | Cement | Opaque"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/111601193529072020121837.jpg",
          code: "W79QPDDR",
          description: [
            "Lines | Squares | Diamonds | Black | Patterns | Graidients | Opaque | White | Round",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/172032872229072020122130.jpg",
          code: "5OF24S7E",
          description: [
            "Lines | Black | Grey | Patterns | Graidients | Opaque | White | Round",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/106324292714072020112952.jpg",
          code: "1PUQOEA4",
          description: ["Lines | Black | Grey | Opaque | White | Round"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/117025686429072020121938.jpg",
          code: "4P7ZF093",
          description: [
            "Lines | Squares | Diamonds | Black | Grey | Graidients | Opaque | White | Round",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/64997227314072020140719.jpg",
          code: "J14O5NVN",
          description: [
            "Squares | Diamonds | Pixels | Green | Red | Pink | Graidients | Opaque | Flowers | Roses | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/186843665524062020080322.jpg",
          code: "LOXD3QIX",
          description: [
            "Lines | Squares | Cracks | Black | Grey | Marble | Surfaces | Opaque | White | Stone",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/19344164514072020110201.jpg",
          code: "HEQ28AGT",
          description: [
            "Lines | Black | Grey | Patterns | Graidients | Opaque | White | Round",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/83324438314072020124701.jpg",
          code: "Y5NOHM9M",
          description: [
            "Lines | Dots | Squares | Black | Grey | Patterns | Opaque | White | Round",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/78987862514072020133707.jpg",
          code: "WQUM9A6J",
          description: ["Grey | Cement | Opaque | White"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/138895166029072020123200.jpg",
          code: "8M2U6OMM",
          description: [
            "Lines | Circles | Diamonds | Triangles | Green | Yellow | Graidients | Opaque",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/208582618114072020104805.jpg",
          code: "ZC9IMU2B",
          description: [
            "Squares | Pixels | Green | Black | Dark Blue | Opaque | Flowers | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/184628344529072020122035.jpg",
          code: "ELB9CDYD",
          description: [
            "Lines | Squares | Geometric | Pixels | Black | Graidients | Opaque | White | Round",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/20032534901072020133017.jpg",
          code: "5l5SBA0Q",
          description: ["Lines | Blue | Purple | Grey | Marble | Opaque"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/38001765329072020125051.jpg",
          code: "XJKX7BNR",
          description: [
            "Lines | Dots | Circles | Diamonds | Geometric | Triangles | Red | Opaque",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/209014974614072020110526.jpg",
          code: "LAL1OYG2",
          description: [
            "Squares | Pixels | Pixelation | Red | Orange | Opaque | Flowers | Floral",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/189895851929072020124926.jpg",
          code: "XJKX7BNR",
          description: [
            "Lines | Squares | Circles | Diamonds | Triangles | Green | Opaque",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/141510298514072020135126.jpg",
          code: "27BTNSD6",
          description: [
            "Circles | Black | Grey | Graidients | Opaque | White | Round",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/179252102014072020123639.jpg",
          code: "RR9GFATA",
          description: ["Lines | Blue | Black | Grey | Marble | Onyx | Opaque"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/160765403201072020131619.jpg",
          code: "I3E4J0NG",
          description: ["Black | Grey | Opaque | White"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/75470691429072020121346.jpg",
          code: "W0HZYXAK",
          description: [
            "Green | Brown | Opaque | Leaves | Flowers | Floral | Round",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/14633702714072020135551.jpg",
          code: "IJTRICJ0",
          description: ["Lines | Yellow | Black | Marble | Patterns | Opaque"],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/104131950929072020122223.jpg",
          code: "6AL949ZT",
          description: [
            "Lines | Squares | Pixels | Black | Grey | Graidients | Opaque | White | Round",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/76384150729072020124223.jpg",
          code: "J4L4QW8S",
          description: [
            "Lines | Circles | Diamonds | Geometric | Triangles | Red | Yellow | Opaque",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/117700459429072020142659.jpg",
          code: "RACLJZ3P",
          description: ["Brown | Orange | Opaque | White | Stone"],
        },
      ],
    },
    {
      image: PIXEL_BLASTER_4_EFFECTS,
      title: strings.PIXEL_BLASTER_4_EFFECTS,
      description: strings.PIXEL_BLASTER_4_EFFECTS,
      href: "pixel_plaster_4_effects",
      images: [
        {
          image:
            "https://glassdesign.online/images/glassdesign/71105809924062020073742.jpg",
          code: "839MS9T8",
          description: [
            "Lines | Colors | Blue | Black | Brown | Purple | Graidients | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/11214461201072020123958.jpg",
          code: "ZM89IJVR",
          description: [
            "Dots | Squares | Circles | Geometric | Triangles | Blue | Black | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/153210912629072020135534.jpg",
          code: "O8RIV05K",
          description: [
            "Lines | Dots | Colors | Green | Red | Purple | Pink | Translucent | Graidients | Transparent | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/100411185329072020134909.jpg",
          code: "BBHNIRSS",
          description: [
            "Green | Black | Grey | Patterns | Translucent | Graidients | Transparent | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/4154007001072020123314.jpg",
          code: "TWNEXTSJ",
          description: [
            "Lines | Squares | Rectangles | Colors | Blue | Green | Yellow | Brown | Orange | Flowers | Vector | Floral | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/195469190101072020123150.jpg",
          code: "3QUH4VQU",
          description: [
            "Lines | PixelBlaster 4 | Black | Grey | Patterns | Graidients | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/102537415724062020073914.jpg",
          code: "N5RJAHOF",
          description: [
            "Lines | Squares | Rectangles | Colors | Blue | Green | Yellow | Black | Orange | Colorful | Patterns | Graidients | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/88828356801072020123733.jpg",
          code: "USNP4PFD",
          description: [
            "Lines | Squares | Geometric | Rectangles | Triangles | PixelBlaster 4 | Blue | Brown | Purple | Orange | Seamless",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/165871490629072020134150.jpg",
          code: "1KLM0F71",
          description: [
            "Lines | Colors | Blue | Yellow | Brown | Orange | Translucent | Graidients | Transparent | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/19688726701072020130221.jpg",
          code: "WT4XXID9",
          description: [
            "Lines | PixelBlaster 4 | Colors | Red | Patterns | Graidients | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/113868775701072020122918.jpg",
          code: "GYGUYCGB",
          description: [
            "Lines | Squares | Rectangles | Colors | Blue | Green | Black | Brown | Flowers | Roses | Vector | Floral | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/33534861428012021090916.jpg",
          code: "ENSJ4ZOQ1",
          description: [
            "Lines | Squares | Geometric | Blue | Black | Seamless | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/141229383724062020073239.jpg",
          code: "EXOWLMDB",
          description: [
            "Lines | Geometric | Black | Purple | Privacy | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/24833596401072020122737.jpg",
          code: "ZJGTVCI3",
          description: [
            "Lines | PixelBlaster 4 | Colors | Green | Red | Black | Brown | Graidients | Privacy | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/5219115129072020131200.jpg",
          code: "3P1IJKO3",
          description: [
            "Lines | Colors | Red | Black | Purple | Pink | Patterns | Translucent | Graidients | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/54928013301072020122504.jpg",
          code: "G6KEC2ZU",
          description: [
            "Lines | PixelBlaster 4 | Colors | Green | Yellow | Patterns | Graidients | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/82973738824062020073446.jpg",
          code: "WYN7MIF",
          description: [
            "Lines | Blue | Black | Dark Blue | Fade | Privacy | Navy | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/124726348429072020133421.jpg",
          code: "TAZ4CLJ4",
          description: [
            "Lines | Colors | Red | Black | Purple | Pink | Translucent | Graidients | Transparent | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/65825137301072020125506.jpg",
          code: "J4300Y4L",
          description: [
            "Lines | Geometric | Blue | Light Blue | Seamless | Privacy | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/113710294124062020074030.jpg",
          code: "SIWW4T6X",
          description: [
            "Lines | Squares | Green | Brown | Graidients | Privacy | Vector",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/99697032801072020124130.jpg",
          code: "GRD5NHNL",
          description: [
            "Lines | Geometric | Colors | Green | Black | Brown | Seamless | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/1603057429072020130806.jpg",
          code: "DYDKD7AK",
          description: [
            "Lines | Colors | Blue | Yellow | Brown | Purple | Pink | Orange | Graidients | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/62789812501072020124434.jpg",
          code: "OYGQX5KT",
          description: [
            "Lines | Circles | Geometric | Triangles | PixelBlaster 4 | Colors | Blue | Seamless | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/32044872620032022123335.jpg",
          code: "6BEI807F",
          description: [
            "Lines | Colors | Green | Yellow | Brown | Grey | Translucent | Graidients | Transparent | Leaves | Flowers | Vector | Floral | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/136400305201072020122333.jpg",
          code: "JCYIKQ18",
          description: [
            "Lines | Green | Yellow | Brown | Patterns | Graidients | Vector | White",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/14117175924062020073559.jpg",
          code: "246JU7M2",
          description: [
            "Lines | Squares | Colors | Red | Pink | Graidients | Fade",
          ],
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/161998150929072020132745.jpg",
          code: "PTTO2WSA",
          description: [
            "Colors | Blue | Green | Red | Yellow | Brown | Orange | Graidients | White",
          ],
        },
      ],
    },
    {
      image: Door,
      title: strings.door,
      description: strings.doorDescription,
      href: "door",
      images: [
        {
          image:
            "https://glassdesign.online/images/glassdesign/141273923430062020151049.jpg",
          features: [
            "Lines | Circles | Geometric | Blue | Green | Pink | Patterns | See-Through | Seamless | Privacy | Vector | White",
          ],
          code: "I7R40BNL",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/107203618030062020145738.jpg",
          features: [
            "Lines | Diamonds | Geometric | Green | Patterns | Casual | See-Through | Seamless | Privacy | Vector | White",
          ],
          code: "JA02QJF5",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/182237169028072020085136.jpg",
          features: [
            "Lines | Circles | Diamonds | Geometric | Triangles | Blue | Patterns | Seamless | Privacy | Vector",
          ],
          code: "8SK7E5A6",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/139719352523062020061510.jpg",
          features: [
            "Lines | Dots | Circles | Geometric | Colors | Pink | Patterns | See-Through | Seamless | Vector",
          ],
          code: "DG4M9SN9",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/126443167427072020140827.jpg",
          features: [
            "Lines | Geometric | Rectangles | Colors | Blue | Brown | Patterns | See-Through | Seamless | Leaves | Flowers | Vector | Floral",
          ],
          code: "7CB5JYXW",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/193906663523062020085352.jpg",
          features: [
            "Lines | Dots | Diamonds | Geometric | Green | Patterns | See-Through | Seamless | Vector",
          ],
          code: "D2L7OF78",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/114641748323062020085951.jpg",
          features: [
            "Lines | Squares | Colors | Blue | Yellow | Pink | Patterns | See-Through | Seamless | Leaves | Vector",
          ],
          code: "B7A6CZ3V",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/44096060527072020141248.jpg",
          features: [
            "Diamonds | Green | Patterns | Seamless | Privacy | Nature | Leaves | Flowers | Vector | Floral",
          ],
          code: "A4XAZ3Y5",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/1415696528072020090241.jpg",
          features: [
            "Lines | Squares | Diamonds | Geometric | Triangles | Blue | Patterns | Seamless | Privacy | Vector",
          ],
          code: "QPDDJMZZ",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/173929181823062020084012.jpg",
          features: [
            "Lines | Geometric | Brown | Patterns | See-Through | Seamless | Privacy | Leaves | Plants | Vector | Floral",
          ],
          code: "CENQEZO3",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/137360842923062020085039.jpg",
          features: [
            "Lines | Dots | Diamonds | Geometric | Green | Patterns | See-Through | Seamless | Privacy | Vector",
          ],
          code: "Y9DGLC2I",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/17997725828072020084709.jpg",
          features: [
            "Lines | Squares | Circles | Geometric | Triangles | Blue | Green | Brown | Patterns | See-Through | Seamless | Privacy | Waves | Vector",
          ],
          code: "VH1VUIBF",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/3172879623062020084852.jpg",
          features: [
            "Lines | Blue | Patterns | See-Through | Seamless | Privacy | Leaves | Flowers | Vector | Floral",
          ],
          code: "GPLFHARD",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/13982939328072020084447.jpg",
          features: [
            "Lines | Dots | Squares | Circles | Geometric | Blue | Green | Pink | Orange | Patterns | See-Through | Seamless | Leaves | Flowers | Floral",
          ],
          code: "LZHXM4YA",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/22902117730062020151253.jpg",
          features: [
            "Lines | Green | Black | Patterns | See-Through | Seamless | Privacy | Leaves | Vector",
          ],
          code: "2QCHUO5A",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/165732276828072020085521.jpg",
          features: [
            "Lines | Squares | Geometric | Triangles | Brown | Marble | Cement | Patterns | Privacy | Waves | Stone",
          ],
          code: "MCYM3E4C",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/1975711630062020150808.jpg",
          features: [
            "Lines | Diamonds | Geometric | Green | Patterns | See-Through | Seamless | Privacy | Vector",
          ],
          code: "3MH1ITXR",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/134378174623062020085723.jpg",
          features: ["2OIETYFG"],
          code: "Lines | Dots | Circles | Geometric | Colors | Black | Pink | Patterns | See-Through | Seamless | Privacy | Vector",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/163967608502082020113702.jpg",
          features: [
            "Black | Marble | Surfaces | Opaque | Dark | Stone | Texture",
          ],
          code: "0SMFEJOI",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/65690017328072020083357.jpg",
          features: [
            "Lines | Colors | Blue | Yellow | Pink | Patterns | Seamless | Leaves | Vector",
          ],
          code: "DQDT6OE4",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/90695206028072020085013.jpg",
          features: [
            "Lines | Squares | Circles | Geometric | Triangles | Colors | Blue | Brown | Patterns | Seamless | Privacy | Waves | Vector",
          ],
          code: "NRG7LY8D",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/137000038627072020134833.jpg",
          features: [
            "Lines | Geometric | Rectangles | Colors | Green | Red | Brown | Pink | Patterns | See-Through | Seamless | Privacy | Vector",
          ],
          code: "ZBF29UGH",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/87374194223062020061254.jpg",
          features: [
            "Lines | Geometric | Rectangles | Green | Pink | Patterns | See-Through | Seamless | Vector",
          ],
          code: "9K20O676",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/106926965123062020084659.jpg",
          features: [
            "Lines | Circles | Blue | Pink | Grey | Patterns | See-Through | Seamless | Privacy | Leaves | Flowers | Vector | Floral",
          ],
          code: "S3XJYZOT",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/37784687222092022101121.jpg",
          features: [
            "Lines | Geometric | Blue | Patterns | See-Through | Seamless | Privacy | Vector",
          ],
          code: "PE2X6ZN8",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/109859434928072020100134.jpg",
          features: [
            "Lines | Geometric | Triangles | Green | Black | Patterns | Seamless | Privacy | Nature | Leaves | Vines | Vector",
          ],
          code: "8BZVGNZA",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/129724513423062020090317.jpg",
          features: [
            "Lines | Geometric | Rectangles | Black | Patterns | See-Through | Seamless | Privacy | Vector",
          ],
          code: "0AA6HCB4",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/185726388830072020100728.jpg",
          features: [
            "Lines | Marble | Surfaces | Opaque | Earth | Stone | Texture",
          ],
          code: "47Z2XCSD",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/165819264727072020140949.jpg",
          features: [
            "Lines | Geometric | Colors | Green | Brown | Patterns | See-Through | Seamless | Privacy | Vector",
          ],
          code: "G904613Q",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/191315653228072020090406.jpg",
          features: [
            "Lines | Dots | Circles | Geometric | Triangles | Black | Patterns | Seamless | Privacy | Vector",
          ],
          code: "1TA16B69",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/23240283428072020090617.jpg",
          features: [
            "Lines | Squares | Diamonds | Geometric | Triangles | Blue | Black | Brown | Patterns | Seamless | Privacy | Vector",
          ],
          code: "ABEJHPSA",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/27774209023062020084136.jpg",
          features: [
            "Geometric | Blue | Brown | See-Through | Seamless | Privacy | Leaves | Infinity | Vector | Floral",
          ],
          code: "1GB4VIA7",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/150857865123062020085208.jpg",
          features: [
            "Lines | Geometric | Green | Patterns | See-Through | Seamless | Privacy | Leaves | Flowers | Vector",
          ],
          code: "R8V9R5YP",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/99353222530062020151808.jpg",
          features: [
            "Lines | Dots | Geometric | Rectangles | Black | Patterns | See-Through | Seamless | Privacy | Vector",
          ],
          code: "70UKTGVE",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/2045015127072020150057.jpg",
          features: [
            "Lines | Geometric | Blue | Grey | Patterns | See-Through | Seamless | Privacy | Vector",
          ],
          code: "LSCK72TV",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/98811725230062020150526.jpg",
          features: [
            "Lines | Dots | Squares | Circles | Geometric | Rectangles | Blue | Black | Patterns | See-Through | Seamless | Vector | White",
          ],
          code: "E22Z33DO",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/2313188827072020142033.jpg",
          features: [
            "Lines | Squares | Diamonds | Geometric | Green | Pink | Patterns | Seamless | Flowers | Roses | Vector | Rope | Floral",
          ],
          code: "7EKA8F30",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/160720577228072020085639.jpg",
          features: [
            "Lines | Dots | Geometric | Triangles | Blue | Green | Brown | Patterns | Seamless | Privacy | Nature | Leaves | Vines | Vector",
          ],
          code: "27LND4HE",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/185274030330062020150113.jpg",
          features: [
            "Geometric | Green | Patterns | Eclectic | See-Through | Opaque | Privacy | Nature | Leaves | Flowers | Plants | Vector | Floral",
          ],
          code: "8AGVKWVA",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/200784750728072020082557.jpg",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Green | Pink | See-Through | Seamless | Leaves | Vector | Floral",
          ],
          code: "FDS6QUYT",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/184758595130062020151639.jpg",
          features: [
            "Lines | Diamonds | Geometric | Green | Black | Patterns | See-Through | Seamless | Privacy | Vector",
          ],
          code: "FZ2R320M",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/2087792630062020145523.jpg",
          features: [
            "Dots | Geometric | Triangles | Blue | Yellow | Brown | Patterns | See-Through | Seamless | Privacy | Vector",
          ],
          code: "CIBTFTEL",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/43524460830062020151422.jpg",
          features: [
            "Lines | Blue | Yellow | Pink | Patterns | See-Through | Seamless | Leaves | Flowers | Vector | Floral",
          ],
          code: "E0X4UP8V",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/106763318829072020145122.jpg",
          features: [
            "Lines | Squares | Circles | Diamonds | Geometric | Patterns | Transparent | See-Through | Seamless",
          ],
          code: "TE6KJ7LA",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/119701095727072020141450.jpg",
          features: [
            "Lines | Circles | Geometric | Blue | Patterns | See-Through | Seamless | Privacy | Infinity | Vector",
          ],
          code: "B2YU7GUN",
        },
        {
          image:
            "https://glassdesign.online/images/glassdesign/96551395127072020144719.jpg",
          features: [
            "Lines | Circles | Colors | Blue | Green | Pink | Orange | Patterns | See-Through | Seamless | Privacy | Leaves | Flowers | Roses | Infinity | Vector",
          ],
          code: "MH39YTSP",
        },
      ],
    },
  ];
};

export default getCategories;
