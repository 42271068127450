import { Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/header/header.jsx";
import HomePage from "./pages/home-page.jsx";
import Footer from "./components/footer/footer.jsx";
import { Products } from "./components/poducts/products.jsx";
import { OurWork } from "./components/work/our-work.jsx";
import { Process } from "./components/process/process.jsx";
import AboutUs from "./components/about-us/about-us.jsx";
import LanguageContext from "./context/language-context.js";
import { useContext } from "react";
import strings from "./assets/locals/locals.js";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import DarkModeContext from "./context/darkmode.js";
import useWindowDimensions from "./context/windowDimensions.js";
import Categories from "./components/categories/categories.jsx";
import { Items } from "./components/items/items.jsx";

function App() {
  const { lang } = useContext(LanguageContext);
  const { darkMode } = useContext(DarkModeContext);
  const { width } = useWindowDimensions();
  strings.setLanguage(lang);

  useEffect(() => {
    if (darkMode) {
      document.getElementsByTagName("body")[0].classList.add("dark");
      document.getElementsByTagName("body")[0].classList.remove("light");
    } else {
      document.getElementsByTagName("body")[0].classList.add("light");
      document.getElementsByTagName("body")[0].classList.remove("dark");
    }
  }, [darkMode]);
  useEffect(() => {
    if (window.particlesJS) {
      window.particlesJS("particles-js", {
        particles: {
          number: {
            value:
              width < 600 ? 25 : width < 800 ? 50 : width > 800 ? 100 : 200,
            density: {
              enable: false,
              value_area: 800,
            },
          },
          color: {
            value: "#f0c394",
          },
          opacity: {
            value: 0.4,
            random: true,
            anim: {
              enable: true,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: true,
              speed: 40,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 200,
            color: "#f0c394",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 0.5,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        retina_detect: true,
      });
    }
  }, [width]);
  return (
    <>
      <div id="particles-js"></div>
      <Header />
      <Grid
        container
        marginTop={4}
        className="App"
        dir={lang === "ar" ? "rtl" : "ltr"}
      >
        <Routes>
          <Route index path="/" element={<HomePage />} exact />
          <Route path="/about-us" element={<AboutUs />} exact />
          <Route path="/products" element={<Products />} exact />
          <Route path="/our-work" element={<OurWork />} exact />
          <Route path="/process" element={<Process />} exact />
          <Route path="/categories" element={<Categories />} exact />
          <Route path="/categories/:title" element={<Items />} exact />
        </Routes>
        <Footer />
      </Grid>
    </>
  );
}

export default App;
