/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { createContext, useState } from "react";
import React from "react";

const LanguageContext = createContext("ar");

export const LanguageContextProvider = ({ children }) => {
  const prevLang = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "ar";
  const [lang, setLang] = useState(prevLang);
  useEffect(() => {
    localStorage.setItem("lang", lang);
  }, [lang]);
  const setLangToEn = () => {
    setLang("en");
    localStorage.setItem("lang", "en");
  };
  const setLangToAr = () => {
    setLang("ar");
  };
  const toggleLang = () => {
    setLang((prevState) => (prevState === "en" ? "ar" : "en"));
  };

  return (
    <LanguageContext.Provider
      value={{ lang, setLangToEn, setLangToAr, toggleLang }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
