import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
  ar: {
    aboutUs: "من نحن",
    aboutUsContent: ` شركة النسيم الوطنية للتجارة شركة متخصصة في مجال تجارة الزجاج بالجملة والتجزئة وجميع مستلزمات  الزجاج ,تقوم الشركة ببيع جميع انواع الزجاج المنتج محليا والمستورد من الخارج , تأسست عام 1988م  في المملكة العربية السعودية ومركزها الرئيسي في مدينة الرياض ولديها خمسة فروع اخرى في الشفا(منطقة الرياض)، والمنطقة الشرقية في مدينة الدمام و مدينة الأحساء والمنطقة الغربية في مدينة جدة.`,
    aboutUsContent2: `وكما يوجد لديها قسم خاص في التقطيع وتشكيل الزجاج واعمال الدبل جلاس والفريم لس وقسم التركيبات مركزه الرئيسي في الرياض. تدعم أعمالنا العديد من مصانع الالمنيوم و الورش المختصة في الزجاج والمرايا من أجل تلبية متطلبات العملاء، كما أن خدماتنا يدعمها معدات والات حديثة مجهزة وكادرمن الموظفين اصحاب كفاءة من أجل القيام بمجموعة متنوعة من العمليات بكفاءة عالية .`,
    companyName: " النسيم",
    ourProducts: "منتجات شركتنا",
    home: "الصفحة الرئيسية",
    productsProcess: "خطوات الانتاج",
    ourWork: "اعمالنا السابقة",
    showrRooms: "غرف الاستحمام",
    framelessDoors: "أبواب بدون إطار",
    Partitions: "بارتشنز",
    UV_HD_Printing: "طباعة بالأشعة فوق البنفسجية عالية الدقة على الزجاج",
    Lamination_Insertion_SmartFilm: "التصفيح – الإدخالات – الفيلم الذكي",
    Mirrors: "المرايا",
    sandPlasting: "التفجير الرملي",
    staindGlassDomesWindowsAndDoors:
      "القباب والنوافذ والأبواب المصنوعة من الزجاج الملون",
    doubleGlass: "زجاج مزدوج",
    polishedAndBeveledGlassTables: "طاولات زجاجية مصقولة ومشطوفة",
    patternGlass: "نمط الزجاج",
    handRails: "الدرابزين",
    ourFabricationDivisionMantifacturesTheFollowing:
      "يقوم قسم التصنيع لدينا بتصنيع المنتجات التالية",
    ourGlassTypesAndSizes: "أنواع وأحجام الزجاج لدينا",
    OurGlassSource: "مواردنا الزجاجية",
    Pilkington: "بيلكنجتون",
    Guardian: "جارديان",
    AGC: "ايه جي سي",
    Sphinx: "سفينكس",
    Emirates: "الإمارات",
    nationalTradingCompany: "الوطنية للتجارة",

    clearFloatThick: "شفاف، عائم، سماكات من 2 مم – حتى 19 مم",
    tintedColored:
      "ملون، برونزي، رمادي، وأزرق، أخضر وأزرق أخضر سماكات 6 مم، 8 مم، 10 مم.",
    reflectiveGlass:
      "زجاج عاكس، برونزي، رمادي، أزرق، أخضر، وردي، سمك شفاف 6 مم فقط .",
    extraClear:
      "شفاف للغاية، منخفض الحديد، سمك أوبتيوايت 4 مم، 5 مم، 6 مم، 8 مم، 10 مم، 12 مم و15 مم.",
    acidEtched: "زجاج محفور بالحامض بسماكة شفافة ومنخفضة الحديد 6 مم و10 مم.",
    mirrorClearBronzeGray: "مرآة، شفاف، برونزي، رمادي، وردي، سمك 6 مم فقط.",
    ourWorkAndProjects: "أعمالنا و مشاريعنا",
    productProcess: "عميلة الانتاج",
    contactUs: "تواصل معنا",
    all: "الكل",
    mall: "الاسواق التجارية",
    hotels: "الفنادق",
    offices: "المكاتب",
    towers: "الابراج",
    residential: "المباني السكنية",
    categories: "الفئات",
    buildingNumber: "رقم الوحدة",
    address: "تفاصيل العنوان",
    companyAddress: "7386 العين - حي الفيصلية",
    zipCode: "الرقم البريدي",
    additionalNumber: "الرقم الاضافي",
    BACKSPLASH: "باكسبلاش",
    BALUSTRADES: "الدرابزينات",
    BIRD_SAFE_PATTERNS: "أنماط آمنة للطيور",
    CMIX: "CMIX",
    COUNTER_TOPS: "قمم مضادة",
    GOLD_PATTERNS: "أنماط الذهب",
    PARTITIONS: "أقسام",
    PATTERNS: "أنماط",
    PIXEL_BLASTER_4_EFFECTS: "تأثيرات بكسل بلاستر 4",
    SHOWERS: "الاستحمام",
    STONE: "أحجار",
    TABLE_TOPS: "قمم الطاولات",
    TEXTURE: "نَسِيج",
    WALL_CLADDING: "تكسية الجدران",
    WOOD: "خشب",
    categoriesTitle:
      "خذ أي مساحة إلى المستوى التالي مع أنماط رائعة وسلسة على الزجاج! تتيح لك الطباعة الرقمية التحكم بسهولة في مناطق مطبوعة متعددة، بينما يساعد عنصر المتجه المتكرر بدقة في إنشاء تصميمات مثالية. يمكن تعديل الصور من حيث الحجم واللون لتناسب أي مساحة أو أثاث.",
    BACKSPLASH_DESCRIPTION:
      "تسمح لنا كسوة الجدران المصممة بعناصر متجهة بإعطاء المساحة لغتها وأسلوبها الخاص. يمكننا بسهولة إعداد وتغيير ألوان الخلفية والنمط. يأخذ التصميم الذكي في الاعتبار الوظيفة مع تنسيق المساحة بصريًا بشكل ممتع.",
    BALUSTRADES_DESCRIPTION:
      "تقدم Dip-Tech تصميمات فريدة للاستخدام في أي تطبيق زجاجي، واستمتع بتصميمات جذابة تناسب كل بيئة تجارية. تعتبر الدرابزينات مثالاً رائعًا للاستخدام الشائع الذي يمكن جعله مميزًا",
    itemID: "رقم العنصر",
    showersDescription:
      "يمكن أن تكون طباعة النمط بمثابة زخرفة، ولكن ليس هذا فقط. الجمع بين الخصوصية والظل باستخدام النمط الصحيح يحول الحمام إلى موقع مطور وشامل",
    partitionDescription:
      "الزجاج مادة شفافة، وهي خاصية تسمح بالخداع البصري وتأثيرات الإضاءة. تسمح الجدران الشفافة المطبوعة جزئيًا بتوسيع النطاق المرئي بالإضافة إلى السماح بدخول الضوء الطبيعي.",

    woodDescription:
      "الزجاج مادة شفافة، وهي خاصية تسمح بالخداع البصري وتأثيرات الإضاءة. تسمح الجدران الشفافة المطبوعة جزئيًا بتوسيع النطاق المرئي بالإضافة إلى السماح بدخول الضوء الطبيعي.",

    stoneDescription:
      "تشمل تصميمات المواد الخام لدينا خامات الرخام والحجر والأسمنت والعقيق. تتكون من صور عالية الجودة وعالية الدقة. يمكن وضع هذه الحجارة في الديكورات الزجاجية الداخلية والخارجية",

    textureDescription:
      "في الوقت الحاضر يتم تصنيع الأنسجة الطبيعية يدويًا عن طريق تقليد الأنماط الطبيعية الموجودة على الحجارة. ولتحقيق ذلك، نقوم بمسح الحجر الأصلي باستخدام معدات مسح عالية الجودة وتحرير احترافي. الملمس الناتج يشبه تمامًا المظهر الطبيعي",

    patternDescription:
      "يعد استخدام الأنماط السلسة طريقة غير عادية لمنح تصميمك بعض العمق والشخصية الإضافية. يتيح لك مقياس النمط وكثافته التحكم في الخصوصية أو التظليل",
    BIRD_SAFE_PATTERNS_DESCRIPTION:
      "تؤدي اصطدامات الزجاج إلى خسائر فادحة في الطيور، مما يهدد الأنواع الشائعة والنادرة على حد سواء. ويتطلب حل مشكلة بهذا الحجم تفكيرا عميقا، وأفكارا جريئة، وإجراءات جماعية. قامت شركة Dip-Tech بإنشاء وجمع أنماط للمساعدة في تقليل اصطدام الطيور. معًا يمكننا بناء مستقبل لا يعد فيه الزجاج تهديدًا رئيسيًا للطيور.",

    company: "شركة",
    cotedGlass: "الزجاج المطلي",
    hdGreyBronzeBlueGreen: "HD رمادي، برونزي، أزرق، أزرق أخضر",
    hdePlusBronzeBlueGreen:
      "HD بالإضافة إلى الرمادي والبرونزي والأزرق والأزرق والأخضر",
    hbNeutral70D: "اتش بي محايد 70 د",
    sliver20: "سيلفر 20",
    sunluxChromaGrey: "سنلوكس كروما جراي، برونزي، أزرق داكن وخفيف، شادو أوريون",
    ObikanGlass: "أوبيكان للزجاج",
    riderGlass: "زجاج رايدر",
    ufgGlass: "يو اف جي للزجاج",
    spacerAndCorner: "فاصل وزاوية بسمك 6.5H-026 مللي متر",

    spacer5: "فاصل 5.5 مم ",
    spacer8: "فاصل 8.5 مم ",
    spacer9: "فاصل 9.5 مم ",
    spacer11: "فاصل 11.5 مم ",
    spacer13: "فاصل 13.5 مم ",
    spacer14: "فاصل 14.5 مم ",
    spacer15: "فاصل 15.5 مم ",
    corner5: "ركن 5.5 مم ",
    corner8: "ركن 8.5 مم ",
    corner9: "ركن 9.5 مم ",
    corner11: "ركن 11.5 مم ",
    corner13: "ركن 13.5 مم ",
    corner14: "ركن 14.5 مم ",
    corner15: "ركن 15.5 مم ",
    corner: "الاركان",
    sillicon: "سيليكون",
    siliconDescription:
      "جاج عازل مكون من مكونين زجاج مانع للتسرب هيكلي من السيليكون مكون من مكونين  أنبوب سيليكون مانع للتسرب محايد بدون حمض 300 مل",
    pvbLaminationFilm: "فيلم التصفيح PVB",
    pvbLaminationFilmDescription:
      "عبارة عن طبقة من البولي فينيل بوتيرال (PVB) مصممة لتطبيقات زجاج الأمان الرقائقي المعماري والطاقة الشمسية والسيارات. سمك PVB 0.38 ملم، 0.76 ملم، 1.14 ملم، 1.52 ملم، 2.28 ملم",
    soundControlInterlayer: "الطبقة البينية للتحكم في الصوت",
    structuralAndSecurityInterlayers: "الطبقات البينية الهيكلية والأمنية",
    door: "الابواب",
    doorDescription:
      "الباب الأمامي هو النقطة المحورية لأي تجاري أو منزلي داخلي وخارجي. يرحب بكم عند المدخل ويترك انطباعًا رائعًا.",
  },
  en: {
    aboutUs: "About Us",
    aboutUsContent: `Al-Naseem National Trading Company is a company specialized in the field of wholesale and retail glass trade and all glass supplies. The company sells all types of glass produced locally and imported from abroad. It was established in 1988 AD in the Kingdom of Saudi Arabia and its main headquarters is in the city of Riyadh. It has five other branches in Al-Shifa (Riyadh region). And the eastern region in the city of Dammam and the city of Al-Ahsa, and the western region in the city of Jeddah.`,
    aboutUsContent2:
      "It also has a special department for cutting and shaping glass, double glass and frameless works, and an installation department whose main headquarters is in Riyadh. Our work supports many aluminum factories and workshops specialized in glass and mirrors in order to meet customer requirements. Our services are also supported by modern, equipped equipment and machines and a cadre of competent employees in order to carry out a variety of operations with high efficiency.",
    companyName: "Al Naseem",
    ourProducts: "Our Products",
    ourWork: "Our Work",
    productsProcess: "Products Process",
    home: "Home Page",
    showrRooms: "Shower Rooms",
    framelessDoors: "FrameLess Doors",
    Partitions: "Partitions",
    UV_HD_Printing: "UV HD Printing on Glass",
    Lamination_Insertion_SmartFilm: "Lamination – Insertions – Smart Film",
    Mirrors: "Mirrors",
    sandPlasting: "Sand blasting",
    staindGlassDomesWindowsAndDoors: "Stained Glass Domes, Windows and Doors",
    doubleGlass: "Double Glass ",
    polishedAndBeveledGlassTables: "Polished and Beveled glass tables",
    patternGlass: "Pattern Glass ",
    handRails: "Handrails",
    ourFabricationDivisionMantifacturesTheFollowing:
      "our fabrication division mantifactures the following products",
    ourGlassTypesAndSizes: "Our Glass Types and Sizes",
    OurGlassSource: "Our Glass Resources ",
    Pilkington: "Pilkington",
    Guardian: "Guardian",
    AGC: "AGC",
    Sphinx: "Sphinx",
    Emirates: "Emirates",
    clearFloatThick: "Clear Float, Thicknesses From 2mm – up to 19mm",
    tintedColored:
      "Tinted Colored, Bronze, Gray, and Blue, Green and Blue Green Thicknesses 6mm, 8mm, 10mm.",
    reflectiveGlass:
      "Reflective Glass, Bronze, Gray, Blue, Green, Pink and Clear Thicknesses 6mm only.",
    extraClear:
      "Extra Clear, Low-Iron, Optiwhite Thicknesses 4mm, 5mm, 6mm, 8mm, 10mm, 12mm and 15mm.",
    acidEtched:
      "Acid Etched Glass on Clear and Low-Iron thicknesses 6mm and 10mm.",
    mirrorClearBronzeGray:
      "Mirror, Clear, Bronze, Gray, Pink, thicknesses 6mm only.",
    ourWorkAndProjects: "Our Work & Projects",
    productProcess: "Product Process",
    contactUs: " Contact Us ",
    residential: "ٌResidential",
    all: "All",
    towers: "Towers",
    hotels: "Hotels",
    mall: "Malls",
    offices: "Offices",
    categories: "Categories",
    buildingNumber: "Building Number",
    address: "Address Details",
    zipCode: "Zip Code",
    additionalNumber: "Additional Number",
    companyAddress: "7368 - Al Ain - Al Faisaliyah Dist ",
    BACKSPLASH: "BACKSPLASH",
    BALUSTRADES: "BALUSTRADES",
    SHOWERS: "SHOWERS",
    PARTITIONS: "PARTITIONS",
    WALL_CLADDING: "WALL_CLADDING",
    COUNTER_TOPS: "COUNTER TOPS",
    TABLE_TOPS: "TABLE TOPS",
    WOOD: "WOOD",
    STONE: "STONE",
    TEXTURE: "TEXTURE",
    PATTERNS: "PATTERNS",
    BIRD_SAFE_PATTERNS: "BIRD SAFE PATTERNS",
    PIXEL_BLASTER_4_EFFECTS: "PIXEL BLASTER 4 EFFECTS",
    GOLD_PATTERNS: "GOLD PATTERNS",
    CMIX: "CMIX",
    categoriesTitle:
      "Take any space to the next level with gorgeous, seamless patterns on glass! Digital printing allows you to easily control multiple printed areas, while a precisely repeating vector element helps create perfect designs. Images can be modified in size and color to flawlessly fit any space and furniture.",
    BACKSPLASH_DESCRIPTION:
      "A wall cladding designed with vector elements allows us to give the space its own language and style. We can easily prepare and change the background colors and the pattern. A smart design considers functionality while pleasantly harmonizing the space visually.",

    BALUSTRADES_DESCRIPTION:
      "Dip-Tech offers unique designs for use on any glass application, enjoy eye-catching designs that suit every commercial setting. Balustrades are a great example of a common use that can be made for special      ",
    itemID: "Item ID",
    showersDescription:
      "Printing a pattern can serve as a decoration, but not just this. Combining privacy and shade using a correct pattern turns a bathroom into an upgraded and inclusive location",
    partitionDescription:
      "Glass is a transparent material, a property that allows optical illusions and lighting effects. Transparent walls that are partially printed allow an expansion of the visible range as well as permitting the entry of natural light.    ",
    woodDescription:
      "Our image processing technique captures the natural beauty of real wood and using it to turn your printed glass surface into a high-quality functional design",
    stoneDescription:
      "Our raw material designs include marble, stone, cement, onyx textures. Composed of high quality and high-resolution images. These stones can be set in interiors & exteriors glass decoration    ",

    textureDescription:
      "Nowadays natural textures are manually made by imitating the natural patterns found on stones. To achieve this, we scan the original stone using high quality scanning equipment and professional editing. The resulting texture is quite like the natural in appearance    ",

    patternDescription:
      "Employing seamless patterns are an extraordinary way to give your design some additional depth and personality. The pattern scale and density allow you to control privacy or shading    ",

    BIRD_SAFE_PATTERNS_DESCRIPTION:
      "Glass collisions take a staggering toll on birds, threatening common and rare species alike. Solving a problem of this size requires big thinking, bold ideas, and collective actions. Dip-Tech has created and collected patterns to help reduce bird collisions. Together we can build a future in which glass is no longer a leading threat to birds.",
    nationalTradingCompany: "National Trading Company",
    company: "Company",
    cotedGlass: "Coted Glass",
    hdGreyBronzeBlueGreen: "HD Grey , Bronze , Blue , Blue Green",
    hdePlusBronzeBlueGreen: "HD plus grey, bronze, blue, blue-green",
    hbNeutral70D: "HO Neurtal 70 D ",
    sliver20: "Sliver 20",
    sunluxChromaGrey:
      "Sunlux Chroma Grey , Bronze , Dark & Light Blue , Shadow Orion",
    ObikanGlass: "Obikan Glass",
    ufgGlass: "UFG Glass",
    riderGlass: "Rider Glass",
    spacerAndCorner: "Spacer And Corner 6.5H-026MM Thick",
    spacer5: "Spacer 5.5 MM",
    spacer8: "Spacer 8.5 MM",
    spacer9: "Spacer 9.5 MM",
    spacer11: "Spacer 11.5 MM",
    spacer13: "Spacer 13.5 MM",
    spacer14: "Spacer 14.5 MM",
    spacer15: "Spacer 15.5 MM",
    corner5: "Corner 5.5 MM",
    corner8: "Corner 8.5 MM",
    corner9: "Corner 9.5 MM",
    corner11: "Corner 11.5 MM",
    corner13: "Corner 13.5 MM",
    corner14: "Corner 14.5 MM",
    corner15: "Corner 15.5 MM",
    corner: "Corner",
    sillicon: "Sillicon",
    siliconDescription:
      " Two Component Sealant insulating Glass ,  Two Component Silicone Structural Sealant Glass  . Silicone Sealant Tube Neutral No Acid 300ML  ",
    pvbLaminationFilmDescription:
      "is a polyvinyl butyral (PVB) film designed for architectural, solar, and automotive laminated safety glass applications. PVB Thicknesses 0.38mm,0.76mm,1.14mm,1.52mm,2.28mm",
    pvbLaminationFilm: "PVB Lamination Film",
    soundControlInterlayer: "sound Control Interlayer",
    structuralAndSecurityInterlayers: "Structural & Security Interlayers",
    door: "Door",
    doorDescription:
      "The front door is the focal point of any commercial or home interior and exterior. It welcomes you at the entrance and imposes a great impression.",
  },
});

export default strings;
