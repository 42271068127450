import strings from "./locals/locals";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
const getRoutes = (lang) => {
  strings.setLanguage(lang);
  return [
    {
      title: strings.home,
      reference: "/",
    },
    {
      title: strings.aboutUs,
      reference: "/about-us",
    },
    {
      title: strings.ourProducts,
      reference: "/products",
    },
    // {
    //   title: strings.productProcess,
    //   reference: "/process",
    // },
    {
      title: strings.ourWork,
      reference: "/our-work",
    },
    {
      title: strings.categories,
      reference: "/categories",
    },
  ];
};

export const socialRoutes = [
  {
    title: "Instgram",
    href: "https://www.instagram.com/nsmglass?igsh=MTI1eXFmNHY0dWJ3cQ%3D%3D",
    icon: <InstagramIcon color="#316FF6" />,
  },
  {
    title: "Face Book",
    href: "https://www.facebook.com/share/rbbKALg9vPJ9ufVN/?mibextid=kFxxJD",
    icon: <FacebookIcon />,
  },
];
export default getRoutes;
