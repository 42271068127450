import { Grid, Paper, Typography, Button } from '@mui/material'
import React from 'react'
// import Carousel from 'react-multi-carousel';
// import "react-multi-carousel/lib/styles.css";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import "./process.css"
import { Swiper, SwiperSlide } from 'swiper/react';

// these are the images  for the carousel ;
import one from "../../assets/images/01.jpg";
import two from "../../assets/images/02.jpg";
import three from "../../assets/images/03.jpg";
import four from "../../assets/images/04.jpg";
import five from "../../assets/images/05.jpg";
import six from "../../assets/images/06.jpg";
import seven from "../../assets/images/08.jpg";
import eight from "../../assets/images/08.jpg";
// import { Pagination } from 'swiper/modules';
// import 'swiper/css';
// import 'swiper/css/pagination';

// import './process.css';

var items = [
    {
        image: one,
        description: ["Optimized glass cutting machine from – LISEC – Austria", "2600 x 3700 mm cutting size"],
        id: 1,
    },
    {
        image: two,
        description: ["Edging machine (Grinding, Beveling, Polishing) – Neptun – Italy"],
        id: 2,
    }
    ,
    {
        image: three,
        description: ["Tempering machine New generation up to 0.02 Low-E – North Glass – China 2440 x 3660 mm"],
        id: 3,
    }
    ,
    {
        image: four,
        description: ["Laminating machine – EVALAM – Spain "],
        id: 4,
    }
    ,
    {
        image: five,
        description: ["Sand Blasting machine for Decorative glazing – IPRO – ", "Sandblasting Area 2600 x 3200 mm "],
        id: 5,
    }

    ,
    {
        image: six,
        description: ["UV HD Printing machine Decorative Colored Art glazing  "],
        id: 6,
    },
    {
        image: seven,
        description: ["Printing on Glass – Wood – Steel -", "Printing Area: 2050 x 3200 mm ", "Roller Coating machine Ceramic printing and Painting – Rollmac – Italy", "Painting Area 2200 x 6000 mm"],
        id: 7,
    }
    ,
    {
        image: eight,
        description: ["Vertical Washing machine W/six Brushes for cleaning Low-E and High performance glass.", "Washing Area 2600 x 3210 mm"],
        id: 8
    }
]
export const Process = () => {

    return (
        <Grid container marginTop={3} padding={4} id="product-process" className="mainContainer">
            <Grid item xs={12}>
                <Typography variant={"h3"} className="title"> OUR GLASS PROCESSING PRODUCTS AND MACHINES INCLUDES</Typography>
            </Grid>
            <Grid item xs={12} padding={3} bgcolor={"red"}>
                {/* <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>Slide 1</SwiperSlide>
                    <SwiperSlide>Slide 2</SwiperSlide>
                    <SwiperSlide>Slide 3</SwiperSlide>
                    <SwiperSlide>Slide 4</SwiperSlide>
                    <SwiperSlide>Slide 5</SwiperSlide>
                    <SwiperSlide>Slide 6</SwiperSlide>
                    <SwiperSlide>Slide 7</SwiperSlide>
                    <SwiperSlide>Slide 8</SwiperSlide>
                    <SwiperSlide>Slide 9</SwiperSlide>
                </Swiper> */}
            </Grid>
        </Grid>
    )
}