import centralBank from "../assets/images/central_bank_thumb.jpg";
import symphonyHotel from "../assets/images/acid_etched_glass_01.jpg";
import fourSeason from "../assets/images/four_season.jpg";
import laminatedLowIron from "../assets/images/laminated_low_iron_01.jpg";
import shopFront from "../assets/images/shop_front.jpg";
import goldSouq from "../assets/images/back_painted_low_iron_glass_02.jpg";
import allMuhallab from "../assets/images/mall_almohallab_glass_handrails.jpeg";
import glassBox from "../assets/images/mirrors_glass_box_01.jpg";
import americanUniversityCollage from "../assets/images/back_painted_glass_black_01_thump.jpg";
import aluminiumOffice from "../assets/images/office_07_thumb.jpg";
import centralBankOfKuwait from "../assets/images/central_bank_thumb.jpg";
import elHamara from "../assets/images/offices_laminated_low_iron_glass_01_thumb.jpg";
import residential from "../assets/images/residential_07.jpg";
import strings from "./locals/locals";

export const getCategories = (lang) => {
  strings.setLanguage(lang);
  return [
    {
      cat: "all",
      title: strings.all,
    },
    {
      cat: "malls",
      title: strings.mall,
    },
    {
      cat: "hotels",
      title: strings.hotels,
    },
    {
      cat: "offices",
      title: strings.offices,
    },
    {
      cat: "towers",
      title: strings.towers,
    },
    {
      cat: "residential",
      title: strings.residential,
    },
  ];
};
export const work = [
  {
    category: "hotels",
    captions: ["Sheraton Four Points", "Glass Laminated Clear"],
    id: 1,
    image: centralBank,
  },
  {
    category: "hotels",
    captions: ["Symphony Hotel", " Acid Etched Glass and ", "Mirrors "],
    id: 2,
    image: symphonyHotel,
  },
  {
    category: "hotels",
    captions: ["Four Season Hotel"],
    id: 3,
    image: fourSeason,
  },
  {
    category: "malls",
    captions: ["The Avenues", "Glass Handrails", " Laminated Low-Iron"],
    id: 4,
    image: laminatedLowIron,
  },
  {
    category: "malls",
    captions: ["The Avenues", "Shop Fronts", "Low Iron Glass "],
    id: 5,
    image: shopFront,
  },
  {
    category: "malls",
    captions: ["The Avenues", "Back Painted Low-Iron Glass", " Gold Souq"],
    id: 6,
    image: goldSouq,
  },
  {
    category: "malls",
    captions: ["Al-Muhallab", "Glass Handrails"],
    id: 7,
    image: allMuhallab,
  },
  {
    category: "offices",
    captions: ["Mirrors and Glass Box", "Alamiri Convention Center"],
    id: 8,
    image: glassBox,
  },
  {
    category: "offices",
    captions: ["American University College", "Back Painted Glass"],
    id: 9,
    image: americanUniversityCollage,
  },
  {
    category: "offices",
    captions: ["Offices", "Aluminium Office Partitions"],
    id: 10,
    image: aluminiumOffice,
  },
  {
    category: "offices",
    captions: ["Offices", "Central Bank of Kuwait"],
    id: 11,
    image: centralBankOfKuwait,
  },
  {
    category: "towers",
    captions: ["Al Hamra", "Laminated Low Iron Glass"],
    id: 12,
    image: elHamara,
  },
  {
    category: "residential",
    captions: ["Residential"],
    id: 13,
    image: residential,
  },
];
