import { Box, Grid, Table, TableBody, TableCell, TableRow } from '@mui/material'
import React, { useContext } from 'react'
import "./about-us.css"
import strings from '../../assets/locals/locals';
import LanguageContext from '../../context/language-context';
import centralBankOfKuwait from "../../assets/images/central_bank_thumb.jpg";

const AboutUs = () => {
    const { lang } = useContext(LanguageContext);
    strings.setLanguage(lang)


    return (
        <Grid container className="mainContainer content animate__animated animate__fadeInLeft" display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Grid Grid item xs={10} sm={12} md={6} marginY={3} padding={2}>
                <div className="aboutus-desc">
                    <h2 className="subTitle">{strings.aboutUs}</h2>
                    <p className="text" >
                        {strings.aboutUsContent}
                    </p>
                    <p className="text" >
                        {strings.aboutUsContent2}
                    </p>
                    <Table >
                        <TableBody >
                            <TableRow style={{ border: "none" }}>
                                <TableCell className="text" >{strings.buildingNumber}:</TableCell>
                                <TableCell className="text" > 7386 </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className="text" >{strings.address}:</TableCell>
                                <TableCell className="text"> {strings.companyAddress}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className="text" >{strings.zipCode}:</TableCell>
                                <TableCell className="text">12883 </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className="text" >{strings.additionalNumber}:</TableCell>
                                <TableCell className="text"> 3904</TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </div>
            </Grid>
            <Grid item xs={10} sm={12} md={4} marginY={3}>
                <Box
                    component="img"
                    Height={"400px"}
                    width="100%"
                    src={centralBankOfKuwait}
                    borderRadius="20px"
                />
            </Grid>
        </Grid >

    )
}

export default AboutUs