import { createContext, useEffect, useState } from "react";
import React from "react";

const DarkModeContext = createContext(false);

export const DarkModeContextProvider = ({ children }) => {
  const prevMode = localStorage.getItem("darkMode")
    ? JSON.parse(localStorage.getItem("darkMode"))
    : true;
  const [darkMode, setDarkMode] = useState(prevMode);

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);
  const toggleDarkMode = () => {
    setDarkMode((prevState) => !prevState);
  };
  return (
    <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};
export default DarkModeContext;
