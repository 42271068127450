


import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// // Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import './categories.css';

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';
import LanguageContext from '../../context/language-context'
import strings from '../../assets/locals/locals'
import { Link } from 'react-router-dom'
import { Box, Grid, Typography } from '@mui/material';
import getCategories from '../../assets/ourCategories';



export default function Categories() {
    const { lang } = useContext(LanguageContext);
    strings.setLanguage(lang);
    const ourCategoriesList = getCategories(lang);

    return (
        <Grid container className="mainContainer" display="flex" justifyContent={"center"} >
            <Grid container className="animate__animated animate__bounceInDown">
                <Grid item xs={12}>
                    <Typography variant={"h2"} className="title"> {strings.categories}</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={'auto'}
                        controller={true}
                        initialSlide={4}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}
                        pagination={true}
                        modules={[EffectCoverflow, Pagination]}
                        className="mySwiper"
                    >
                        {ourCategoriesList.map((el, index) => (
                            <SwiperSlide key={el.href}>
                                <Grid container>
                                    <Link to={`/categories/${el.href}`} state={{ ...el, index }} >
                                        <Grid item xs={12}>
                                            <Grid item xs={12} textAlign={"center"} padding={2}>
                                                <Typography variant="span" className="text" marginTop={3} textAlign={"center"}> {el.title} </Typography>
                                            </Grid>
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: "80%",
                                                    width: 200,
                                                }}
                                                className="catImage"
                                                alt={el.href}
                                                src={el.image}
                                            />
                                        </Grid></Link>

                                </Grid>
                            </SwiperSlide>
                        ))}

                    </Swiper>
                </Grid>
            </Grid>
        </Grid >
    );
}


