import { Grid } from '@mui/material'
import { MainHeading } from '../components/mainHeading/mainHeading';

const HomePage = () => {
    return (
        <Grid container className="mainContainer">
            <MainHeading />
        </Grid >
    )
}

export default HomePage