import { Box, Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'

import "./mainHeading.css";
import strings from '../../assets/locals/locals';
import LanguageContext from '../../context/language-context';
import mainHeading from "../../assets/images/mainHeading.jpg";
import useWindowDimensions from '../../context/windowDimensions';


export const MainHeading = () => {
    const { lang } = useContext(LanguageContext);
    const { width } = useWindowDimensions();

    strings.setLanguage(lang)

    return (
        <Grid container className="mainHeading animate__animated animate__zoomInUp" display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={10} sm={12} md={6} className="content">
                <Typography variant="h1" fontSize={width < 600 ? width < 550 ? "2.5rem" : "6rem" : "6rem"} fontWeight={"100"}>
                    {lang === "ar" && strings.company} <Typography variant="span" color={"#ccc"} fontWeight={"bolder"} >{strings.companyName}</Typography> <br />
                    <Typography variant='span' fontWeight={"100"} fontSize={width < 600 ? width < 550 ? "1.5rem" : "5rem" : "5rem"}>{strings.nationalTradingCompany}</Typography>
                </Typography>
            </Grid>
            <Grid item xs={10} sm={12} md={5} columnGap={1} className="content">
                <Grid columnGap={2} >
                    <Box
                        component="img"
                        height={width > 600 ? "500px" : "100%"}
                        minHeight={"250px"}
                        width="100%"
                        src={mainHeading}
                        borderRadius="20px"
                    />
                </Grid>
            </Grid>
        </Grid >
    )
}
