import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.svg"
import getRoutes, { socialRoutes } from "../../assets/routes";
import { Box, Grid, List, Typography } from "@mui/material";
import "./footer.css";
import { useContext } from "react";
import LanguageContext from "../../context/language-context";
import strings from "../../assets/locals/locals";

const Footer = () => {
    const { lang } = useContext(LanguageContext);
    strings.setLanguage(lang)
    const routes = getRoutes(lang);
    return (
        <Grid container className="footer">
            <Grid item xs={12} sm={12} md={6} xl={3} textAlign={"center"} display="flex" alignItems={"center"} justifyContent={"center"} >
                <Link to="/" className="logo">
                    <Box
                        component="img"
                        height="100px"
                        alt="Logo"
                        src={Logo}
                    />
                </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={6} xl={6}>
                <Grid container>
                    <Grid item xs={12} textAlign={"center"} display="flex" alignItems={"center"} justifyContent={"center"}>
                        <List disablePadding className="footer-links routesList">
                            {routes.map((el) => {
                                return (
                                    <li className="nav-item" key={el.title} style={{ display: "flex", padding: "10px 15px", color: "white" }}>
                                        <Link to={el.reference} > | {el.title} | </Link>
                                    </li>
                                )
                            })}
                        </List>
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} xl={3} textAlign={"center"} display="flex" alignItems={"center"} justifyContent={"center"}>
                <Grid container>
                    <Grid item xs={12} textAlign={"center"} justifyContent={"center"} display={"flex"} alignItems={"end"}>
                        {/* <Typography vriant="h2" display="block" fontSize={"2rem"} className="title-p">{strings.contactUs} </Typography> */}
                        <ul className="footer-links routesList ">
                            {socialRoutes.map(el => {
                                return (
                                    <li className="nav-item d-flex" key={el.title}>
                                        <a href={el.href} target="_blank" rel="noreferrer"  >
                                            {el.icon}
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
                <Typography vriant="span" className="footer-company-name" justifyContent={"center"} display={"flex"} alignItems={"center"}>
                    {strings.companyName} <span style={{ color: "#0477BF", fontSize: "15px", padding: "0px 10px " }} > &#9400;</span>
                </Typography>
            </Grid>
        </Grid >
    )
}
export default Footer;