import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Logo from "../../assets/images/logo.svg";
import getRoutes from "../../assets/routes"
import { Link } from 'react-router-dom';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import "./header.css";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import DarkModeContext from '../../context/darkmode';
import LanguageContext from '../../context/language-context';

const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const { darkMode, toggleDarkMode } = React.useContext(DarkModeContext)
  const { lang, toggleLang } = React.useContext(LanguageContext)
  const routes = getRoutes(lang);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };



  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };



  return (
    <div dir={lang === "ar" ? "rtl" : "ltr"}>
      <AppBar className="header" position='sticky'>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Link to="/" className="hideLogo">
              <Box
                component="img"
                sx={{
                  height: 75,
                  width: 200,
                  maxHeight: { xs: 150, md: 150 },
                  maxWidth: { xs: 150, md: 150 },
                }}
                alt="Logo"
                src={Logo}
              />
            </Link>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
                dir={lang === "ar" ? "rtl" : "ltr"}
              >
                {routes.map((page) => (
                  <MenuItem key={page.title} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">
                      <Link className="routeLink" to={page.reference}>{page.title}</Link>
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                justifyContent: "center"
              }}
            >
              <Link to="/">
                <Box
                  component="img"
                  sx={{
                    height: 75,
                    width: 200,
                    maxHeight: { xs: 150, md: 150 },
                    maxWidth: { xs: 150, md: 150 },
                  }}
                  alt="Logo"
                  src={Logo}
                />
              </Link>
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className="routesList">
              {routes.map((page) => (
                <Link className="routeLink" key={page.title} to={page.reference}>
                  <Button
                    className="mainButton"
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    {page.title}
                  </Button>
                </Link>
              ))}
            </Box>
            <Button onClick={toggleDarkMode}> {darkMode ? <LightModeIcon style={{ color: '#FDB813' }} /> : <DarkModeIcon style={{ color: '#ccc' }} />} </Button>
            <Button onClick={toggleLang} style={{ color: '#fff' }}  > {lang === "ar" ? "EN" : "AR"} </Button>
            <Button >
              <a href="tel:+966590140867" ><LocalPhoneIcon style={{ color: '#ccc', height: "20px", padding: "0px", margin: "0px", display: "flex" }} /></a>
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
export default Header;